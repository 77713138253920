import { createSlice } from '@reduxjs/toolkit';
import { TMeta } from 'types';
import { TCaseGroupServiceProvider } from 'types/cases';
import { TServiceProvider } from 'types/service-provider';

interface ServiceProviderState {
  serviceProviders: TServiceProvider[];
  serviceProviderDetail: TServiceProvider | null;
  groupServiceProviders: TCaseGroupServiceProvider[];
  meta: TMeta;
}

const initialState: ServiceProviderState = {
  serviceProviders: [],
  serviceProviderDetail: <TServiceProvider>{},
  groupServiceProviders: [],
  meta: <TMeta>{}
};

const serviceProviderSlice = createSlice({
  name: 'serviceProvider',
  initialState,
  reducers: {
    getAllServiceProviders(state, action) {
      state.serviceProviders = action.payload.results || [];
    },
    getAllServiceProvidersWithPagination(state, action) {
      state.serviceProviders = action.payload.results || [];
      state.meta = action.payload.meta || {};
    },
    getAllGroupServiceProviders(state, action) {
      state.groupServiceProviders = action.payload.results || [];
    },
    getOneServiceProvider(state, action) {
      state.serviceProviderDetail = action.payload.row || null;
    },
    addOneServiceProvider(state, action) {
      state.serviceProviders = [...state.serviceProviders, action.payload.row];
    },
    setOneServiceProvider(state, action) {
      state.serviceProviders = state.serviceProviders.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    removeOneServiceProvider(state, action) {
      state.serviceProviders = state.serviceProviders.filter(
        item => item.id !== action.payload.id
      );
    }
  }
});

export const {
  getAllServiceProviders,
  getAllServiceProvidersWithPagination,
  getAllGroupServiceProviders,
  getOneServiceProvider,
  addOneServiceProvider,
  setOneServiceProvider,
  removeOneServiceProvider
} = serviceProviderSlice.actions;
export default serviceProviderSlice.reducer;
