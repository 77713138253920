import { Formik, FormikHelpers } from 'formik';
import { PreIntakeValidationSchema } from '../../../../validation-schema';
import { Form, Nav } from 'react-bootstrap';
import Button from '../../../base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faListAlt } from '@fortawesome/free-regular-svg-icons';
import { faClose, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import React, { useMemo } from 'react';
import { TPreIntake, TPreIntakeValidation } from '../../../../types/intake';
import { useTranslation } from 'react-i18next';

interface PreIntakeValidationFormProps {
  preIntake: TPreIntake;
  onSubmit: (data: TPreIntakeValidation) => void;
  loading: boolean;
}

const PreIntakeValidationForm = ({
  preIntake,
  onSubmit,
  loading
}: PreIntakeValidationFormProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const initialValues: TPreIntakeValidation = useMemo(() => {
    return {
      decision: 'validate',
      title: '',
      description: '',
      findings: '',
      conclusion: ''
    };
  }, [preIntake]);
  const handleOnSubmit = (
    values: TPreIntakeValidation,
    { resetForm }: FormikHelpers<TPreIntakeValidation>
  ) => {
    onSubmit(values);
    resetForm();
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={PreIntakeValidationSchema}
      onSubmit={handleOnSubmit}
    >
      {({
        values,
        setFieldValue,
        handleBlur,
        handleChange,
        errors,
        isValid,
        touched,
        handleSubmit
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Nav variant="underline" className="mb-3">
              <Nav.Item>
                <Nav.Link
                  className={values?.decision == 'validate' ? 'active' : ''}
                >
                  <Button
                    variant="subtle-primary"
                    className="w-100"
                    startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
                    onClick={() => {
                      setFieldValue('decision', 'validate');
                    }}
                  >
                    {t('validate')}
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={values?.decision == 'reject' ? 'active' : ''}
                >
                  <Button
                    variant="subtle-danger"
                    className="w-100"
                    startIcon={<FontAwesomeIcon icon={faClose} />}
                    onClick={() => {
                      setFieldValue('decision', 'reject');
                    }}
                  >
                    {t('reject')}
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    values?.decision == 'save_to_history' ? 'active' : ''
                  }
                >
                  <Button
                    variant="subtle-secondary"
                    className="w-100"
                    startIcon={<FontAwesomeIcon icon={faListAlt} />}
                    onClick={() => {
                      setFieldValue('decision', 'save_to_history');
                    }}
                  >
                    {t('save_to_history')}
                  </Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            {values.decision == 'save_to_history' ? (
              <>
                <Form.Group className="mb-3" controlId="title">
                  <Form.Label> {t('title')}</Form.Label>
                  <Form.Control
                    placeholder={t('title')}
                    className={`${
                      touched.title && errors.title ? 'is-invalid' : ''
                    }`}
                    name="title"
                    value={values.title || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.title && errors.title && (
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="description">
                  <Form.Label> {t('description')}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={t('description')}
                    name="description"
                    value={values.description || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.description && errors.description && (
                    <span className="text-danger">{errors.description}</span>
                  )}
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group className="mb-3" controlId="findings">
                  <Form.Label> {t('findings')}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={t('findings')}
                    name="findings"
                    value={values.findings || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.findings && errors.findings && (
                    <span className="text-danger">{errors.findings}</span>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="conclusion">
                  <Form.Label> {t('conclusion')}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={t('conclusion')}
                    name="conclusion"
                    value={values.conclusion || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.conclusion && errors.conclusion && (
                    <span className="text-danger">{errors.conclusion}</span>
                  )}
                </Form.Group>
              </>
            )}

            <div className="mt-4">
              <Button
                type="submit"
                disabled={loading || !isValid}
                variant="success"
                className="w-100 rounded-pill"
                startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
              >
                {t('submit')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default PreIntakeValidationForm;
