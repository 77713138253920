import { createSlice } from '@reduxjs/toolkit';
import { TUser } from '../../../types/user';
import { TMeta } from '../../../types';

interface UserState {
  users: TUser[];
  user: TUser;
  meta: TMeta;
}

const initialState: UserState = {
  users: [],
  user: <TUser>{},
  meta: <TMeta>{}
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAllUser(state, action) {
      state.users = action.payload.results || [];
      state.meta = action.payload.meta || <TMeta>{};
    },
    addOneUser(state, action) {
      state.users = [...state.users, action.payload.row];
    },
    setOneUser(state, action) {
      state.users = state.users.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    removeOneUser(state, action) {
      state.users = state.users.filter(item => item.id !== action.payload.id);
    }
  }
});

export const { setAllUser, addOneUser, setOneUser, removeOneUser } =
  userSlice.actions;
export default userSlice.reducer;
