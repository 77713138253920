export const onBehalfArray = [
  {
    label: 'Myself',
    value: 'myself'
  },
  {
    label: 'Others',
    value: 'others'
  },
  {
    label: 'Group',
    value: 'group'
  }
];
export const genders = [
  {
    label: 'Male',
    value: 'M'
  },
  {
    label: 'Female',
    value: 'F'
  }
];

export const ageRanges = [
  {
    label: 'Old',
    value: 'old'
  },
  {
    label: 'Adult',
    value: 'adult'
  },
  {
    label: 'Child',
    value: 'child'
  }
];

export const statuses = [
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'InActive',
    value: 'in_active'
  }
];
export const recipients = [
  {
    label: 'Individual',
    value: 1
  },
  {
    label: 'Family',
    value: 2
  },
  {
    label: 'Group',
    value: 3
  }
];
