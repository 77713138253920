import * as Yup from 'yup';

export const PreIntakeSchema = Yup.object().shape({
  on_behalf: Yup.string().required('The on_behalf field is required.'),
  individual_first_name: Yup.string().required(
    'The First Name field is required.'
  ),
  individual_last_name: Yup.string().required(
    'The Last Name field is required.'
  ),
  individual_phone_number: Yup.string()
    .required('The Phone Number field is required.')
    .matches(/^[0-9]+$/, 'The Phone Number must be numeric.')
    .max(10, 'The Phone Number  must not be more than 10.'),
  individual_address: Yup.string().required(
    'The Current Address field is required.'
  ),
  individual_email: Yup.string()
    .email()
    .required('The Email Address field is required.'),
  individual_relationship: Yup.string().when('on_behalf', {
    is: (on_behalf: string | undefined) => on_behalf === 'others',
    then: schema =>
      schema
        .required('The Relationship field is required.')
        .required('The Relationship field is required.')
  }),

  province: Yup.string().required('The Province field is required.'),
  district: Yup.string().required('The District field is required.')
});

export const PreIntakeValidationSchema = Yup.object().shape({
  decision: Yup.string().required('The Decision field is required.'),

  title: Yup.string().when('decision', {
    is: (decision: string | undefined) => decision === 'save_to_history',
    then: schema => schema.required('The Title field is required.')
  }),
  description: Yup.string().when('decision', {
    is: (decision: string | undefined) => decision === 'save_to_history',
    then: schema => schema.required('The Description field is required.')
  }),
  findings: Yup.string().when('decision', {
    is: (decision: string | undefined) => decision !== 'save_to_history',
    then: schema => schema.required('The Findings field is required.')
  }),
  conclusion: Yup.string().when('decision', {
    is: (decision: string | undefined) => decision !== 'save_to_history',
    then: schema => schema.required('The Conclusion field is required.')
  })
});
export const TrackCaseSchema = Yup.object().shape({
  pre_intake_case_id: Yup.string().required(
    'The Pre Case ID field is required.'
  ),
  individual_first_name: Yup.string().required(
    'The First Name field is required.'
  )
});
