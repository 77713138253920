import { createSlice } from '@reduxjs/toolkit';
import { TLocation } from '../../../types/mdm';

interface LocationState {
  locations: TLocation[];
  treeLocations: TLocation[];
  location: TLocation;
}

const initialState: LocationState = {
  locations: [],
  treeLocations: [],
  location: <TLocation>{}
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    getAllLocation(state, action) {
      state.locations = action.payload.results || [];
    },
    getAllLocationWithTree(state, action) {
      state.treeLocations = action.payload.results || [];
    },
    getOneLocation(state, action) {
      state.location = action.payload.row || null;
    },
    createOneLocation(state, action) {
      state.locations = [...state.locations, action.payload.row];
    },
    updateOneLocation(state, action) {
      state.locations = state.locations.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },

    removeOneLocation(state, action) {
      state.locations = state.locations.filter(
        item => item.id !== action.payload.id
      );
    }
  }
});

export const {
  getAllLocation,
  getAllLocationWithTree,
  getOneLocation,
  createOneLocation,
  updateOneLocation,
  removeOneLocation
} = locationSlice.actions;
export default locationSlice.reducer;
