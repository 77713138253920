import { TCaseGroupServiceProvider } from 'types/cases';
import { useDispatch, useSelector } from '../../../store';
import {
  getAllServiceProviders,
  getAllServiceProvidersWithPagination,
  getAllGroupServiceProviders,
  getOneServiceProvider,
  addOneServiceProvider,
  setOneServiceProvider,
  removeOneServiceProvider
} from '../../../store/reducers/service/serviceProviderSlice';
import ServiceProviderService from 'services/service/ServiceProviderService';
import {
  TServiceCategory,
  TFilterServiceProvider,
  TServiceProvider,
  TServiceCategoryProvider
} from 'types/service-provider';

const useServiceProviderHook = () => {
  const dispatch = useDispatch();
  const {
    serviceProviders,
    serviceProviderDetail,
    groupServiceProviders,
    meta
  } = useSelector(state => state.serviceProvider);

  const fetchAllServiceProvider = async (): Promise<TServiceProvider[]> => {
    try {
      const resp = await ServiceProviderService.fetchAll();
      const results: TServiceProvider[] = resp?.data?.data || [];
      dispatch(getAllServiceProviders({ results }));
      return results;
    } catch (e) {
      dispatch(getAllServiceProviders({ results: [] }));
      console.log('getAllServiceProviders', e);
      throw e;
    }
  };

  const fetchAllServiceProviderWithPagination = async (
    filters: TFilterServiceProvider
  ): Promise<TServiceProvider[]> => {
    try {
      const resp = await ServiceProviderService.fetchAllWithPagination(filters);
      const meta = resp?.data?.data?.meta || {};
      const results: TServiceProvider[] = resp?.data?.data?.rows || [];
      dispatch(getAllServiceProvidersWithPagination({ results, meta }));
      return results;
    } catch (e) {
      dispatch(getAllServiceProvidersWithPagination({ results: [], meta: {} }));
      console.log('getAllServiceProvidersWithPagination', e);
      throw e;
    }
  };

  const fetchAllGroupServiceProvider = async (
    caseId: string,
    filters: { [key: string]: string }
  ): Promise<TCaseGroupServiceProvider[]> => {
    try {
      const resp = await ServiceProviderService.fetchAllGroupServiceProvider(
        caseId,
        filters
      );
      const results: TCaseGroupServiceProvider[] = resp?.data?.data || [];
      dispatch(getAllGroupServiceProviders({ results }));
      return results;
    } catch (e) {
      console.log('fetchAllGroupServiceProvider', e);
      throw e;
    }
  };

  const fetchServiceProvidersWithIssueCategory = async (
    data: TServiceCategory
  ): Promise<TServiceCategoryProvider> => {
    try {
      const resp =
        await ServiceProviderService.fetchServiceProvidersWithIssueCategory(
          data
        );
      const results: TServiceCategoryProvider = resp?.data?.data || {};
      return results;
    } catch (e) {
      console.log('getServiceProvidersWithIssueCategory', e);
      throw e;
    }
  };

  const fetchOneServiceProvider = async (
    id: string
  ): Promise<TServiceProvider> => {
    try {
      const resp = await ServiceProviderService.fetchOne(id);
      const row = resp?.data?.data || null;

      dispatch(getOneServiceProvider({ row }));
      return row;
    } catch (e) {
      dispatch(getOneServiceProvider({ row: null }));
      console.log('getOneServiceProvider', e);
      throw e;
    }
  };

  const createServiceProvider = async (
    data: TServiceProvider
  ): Promise<TServiceProvider> => {
    try {
      const resp = await ServiceProviderService.create(data);
      const row = resp?.data?.data || null;

      dispatch(addOneServiceProvider({ row }));
      return row;
    } catch (e) {
      console.log('addOneServiceProvider', e);
      throw e;
    }
  };

  const updateServiceProvider = async (id: number, data: TServiceProvider) => {
    try {
      const resp = await ServiceProviderService.update(id, data);
      const row = resp?.data?.data || null;

      dispatch(setOneServiceProvider({ id, row }));
    } catch (e) {
      console.log('setOneServiceProvider', e);
      throw e;
    }
  };

  const deleteServiceProvider = async (id: number) => {
    try {
      await ServiceProviderService.destroy(id);
      dispatch(removeOneServiceProvider({ id }));
    } catch (e) {
      console.log('removeOneServiceProvider', e);
      throw e;
    }
  };

  return {
    fetchAllServiceProvider,
    fetchAllServiceProviderWithPagination,
    fetchAllGroupServiceProvider,
    fetchServiceProvidersWithIssueCategory,
    fetchOneServiceProvider,
    createServiceProvider,
    updateServiceProvider,
    deleteServiceProvider,

    meta,
    serviceProviders,
    serviceProviderDetail,
    groupServiceProviders
  };
};

export default useServiceProviderHook;
