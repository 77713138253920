import { TServiceType } from 'types/service-provider';
import api from '../../utils/api';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/case/assessment/service-types`;

const fetchAll = () => {
  return api.get(`${authEndpoint}`);
};

const fetchOne = (id: number) => {
  return api.get(`${authEndpoint}/${id}`);
};

const create = (data: TServiceType) => {
  return api.post(`${authEndpoint}`, data);
};

const update = (id: number, data: TServiceType) => {
  return api.put(`${authEndpoint}/${id}`, data);
};

const destroy = (id: number) => {
  return api.delete(`${authEndpoint}/${id}`);
};

export default {
  fetchAll,
  fetchOne,
  create,
  update,
  destroy
};
