import moment from 'moment';

const timestampFormat = 'YYYY-MM-DD hh:mm';
const dateFormat = 'YYYY-MM-DD';
const timestampFormatPeriod = 'YYYY-MM-DD hh:mm a';

export const todayDate = () => {
  return moment().format('YYYY-MM-DD');
};
export const todayDateInNumber = () => {
  return moment().format('YYYYMMDDhhmmss');
};
// eslint-disable-next-line

export const convertTimestamp = timestamp => {
  return timestamp
    ? moment(timestamp, timestampFormatPeriod).format(timestampFormatPeriod)
    : '';
};
export const convertTimestampToDate = timestamp => {
  return timestamp
    ? moment(timestamp, timestampFormat).format('YYYY-MM-DD')
    : '';
};
export const convertTimestampToHumanDate = timestamp => {
  return timestamp
    ? moment(timestamp, timestampFormat).format('MMMM Do , YYYY - hh:mm a')
    : '';
};
export const convertTimeDate = timestamp => {
  return timestamp ? moment(timestamp, timestampFormat).format(dateFormat) : '';
};
export const convertTimeToHumanTime = timestamp => {
  return timestamp ? moment(timestamp, timestampFormat).format('hh:mm a') : '';
};

export const isValidDate = date => {
  // console.log(date)
  // console.log(moment(date,dateFormat,true).isValid())
  return moment(date, dateFormat, true).isValid();
};
