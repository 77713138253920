import api from '../../utils/api';
import { TPreIntakeValidation } from '../../types/intake';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/case/intake/pre-intake/validate`;

const fetchAll = () => {
  return api.get(`${authEndpoint}`);
};
const fetchOne = (id: number) => {
  return api.get(`${authEndpoint}/${id}`);
};
const create = (preIntakeId: number, data: TPreIntakeValidation) => {
  return api.post(`${authEndpoint}/${preIntakeId}`, data);
};
const update = (id: number, data: TPreIntakeValidation) => {
  return api.put(`${authEndpoint}/${id}`, data);
};
const destroy = (id: number) => {
  return api.delete(`${authEndpoint}/${id}`);
};

export default {
  fetchAll,
  fetchOne,
  create,
  update,
  destroy
};
