import { useDispatch, useSelector } from '../../../store';
import { TLocation } from '../../../types/mdm';
import LocationService from '../../../services/mdm/LocationService';
import {
  createOneLocation,
  getAllLocation,
  getAllLocationWithTree,
  getOneLocation,
  removeOneLocation,
  updateOneLocation
} from '../../../store/reducers/mdm/locationSlice';

const useLocationHook = () => {
  const dispatch = useDispatch();
  const { locations, location, treeLocations } = useSelector(
    state => state.location
  );

  const fetchAllLocation = async (): Promise<TLocation[]> => {
    try {
      const resp = await LocationService.fetchAll();
      const results: TLocation[] = resp?.data?.data || [];
      dispatch(getAllLocation({ results }));
      return results;
    } catch (e) {
      dispatch(getAllLocation({ results: [] }));
      console.log('fetchAllLocation', e);
      throw e;
    }
  };
  const fetchAllLocationWithTree = async (): Promise<TLocation[]> => {
    try {
      const resp = await LocationService.fetchAllWithTreeStructure();
      const results: TLocation[] = resp?.data?.data || [];
      dispatch(getAllLocationWithTree({ results }));
      return results;
    } catch (e) {
      console.log('fetchAllLocation', e);
      throw e;
    }
  };
  const fetchOneLocation = async (id: number): Promise<TLocation> => {
    try {
      const resp = await LocationService.fetchOne(id);
      const row = resp?.data?.data || null;

      dispatch(getOneLocation({ row }));
      return row;
    } catch (e) {
      dispatch(getOneLocation({ row: null }));
      console.log('fetchOneLocation', e);
      throw e;
    }
  };
  const createLocation = async (data: TLocation): Promise<TLocation> => {
    try {
      const resp = await LocationService.create(data);
      const row = resp?.data?.data || null;

      dispatch(createOneLocation({ row }));
      return row;
    } catch (e) {
      console.log('createLocation', e);
      throw e;
    }
  };
  const updateLocation = async (id: number, data: TLocation) => {
    try {
      await LocationService.update(id, data);
      dispatch(updateOneLocation({ id, row: data }));
    } catch (e) {
      console.log('updateLocation', e);
      throw e;
    }
  };
  const deleteLocation = async (id: number) => {
    try {
      await LocationService.destroy(id);
      dispatch(removeOneLocation({ id }));
    } catch (e) {
      console.log('deleteLocation', e);
      throw e;
    }
  };
  return {
    fetchAllLocation,
    fetchAllLocationWithTree,
    fetchOneLocation,
    createLocation,
    updateLocation,
    deleteLocation,
    locations,
    treeLocations,
    location
  };
};

export default useLocationHook;
