import { createBrowserRouter, RouteObject } from 'react-router-dom';
import Error404 from 'pages/error/Error404';
import App from 'App';
import AuthRoutes from './AuthRoutes';
import Error500 from '../pages/error/Error500';
import ServiceProviders from '../pages/service-providers';
import { lazy, Suspense } from 'react';
import PhoenixLoader from '../components/common/PhoenixLoader';
import PublicRoutes from './PublicRoutes';
import PreIntakeRoutes from './PreIntakeRoutes';
import CaseRoutes from './CaseRoutes';
import UserManagementRoutes from './UserManagementRoutes';
import MainLayoutProvider from '../providers/MainLayoutProvider';
import AuthGuard from '../utils/route-guard/AuthGuard';
import MainLayout from '../layouts/MainLayout';
import Error403 from '../pages/error/Error403';
import SettingsRoutes from './SettingsRoutes';

const Profile = lazy(() => import('pages/profile'));
const Dashboard = lazy(() => import('pages/dashboard'));

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      PublicRoutes,
      AuthRoutes,
      PreIntakeRoutes,
      CaseRoutes,
      UserManagementRoutes,
      SettingsRoutes,

      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          </MainLayoutProvider>
        ),
        children: [
          {
            path: '/dashboard',
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                {' '}
                <Dashboard />{' '}
              </Suspense>
            )
          },
          {
            path: '/service-providers',
            children: [
              {
                path: '/service-providers',
                element: <ServiceProviders />
              }
            ]
          },
          {
            path: '/profile',
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                <Profile />
              </Suspense>
            )
          }
        ]
      },
      {
        path: '*',
        element: <Error404 />
      },
      {
        path: '/error/server-error',
        element: <Error500 />
      },
      {
        path: '/error/403',
        element: <Error403 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
