import React from 'react';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBox from 'components/common/SearchBox';
import { Col, Form, OffcanvasProps, Row } from 'react-bootstrap';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/base/Button';
import {
  TFilterServiceProvider,
  TServiceProvider,
  TServiceType
} from 'types/service-provider';
import PhoenixLoader from 'components/common/PhoenixLoader';
import { pageCount } from 'helpers/utils';
import useServiceProviderHook from 'hooks/modules/service/useServiceProviderHook';
import ServiceProviderTable, {
  serviceProviderColumns
} from 'components/modules/service-provider/table/ServiceProviderTable';
import ServiceProviderDetail from 'components/modules/service-provider/detail/ServiceProviderDetail';
import ServiceProviderForm from 'components/modules/service-provider/forms/ServiceProviderForm';
import { toast } from 'react-toastify';
import useServiceTypeHook from 'hooks/modules/service/useServiceTypeHook';
import { Formik, FormikProps } from 'formik';
import { checkScope } from 'helpers/auth';

//initial data
const initFilter: TFilterServiceProvider = {
  page: 1,
  limit: 10,
  status: '',
  search: '',
  service_type: '',
  service_type_ids: ''
};

const initialValue: TServiceProvider = {
  id: undefined,
  service_type: '',
  name: '',
  address: '',
  phone_number: '',
  email: '',
  website: '',
  registered_date: '',
  note: '',
  service_type_ids: [],
  area_coverage: '',
  status: '',
  service_types: []
};

const ServiceProviders = () => {
  const {
    fetchAllServiceProviderWithPagination,
    fetchOneServiceProvider,
    createServiceProvider,
    updateServiceProvider,
    deleteServiceProvider,
    serviceProviders,
    serviceProviderDetail,
    meta
  } = useServiceProviderHook();
  const { fetchAllServiceType, serviceTypes } = useServiceTypeHook();
  const [searchInput, setSearchInput] = React.useState<string>('');
  const [filter, setFilter] =
    React.useState<TFilterServiceProvider>(initFilter);
  const [offCanvas, setOffCanvas] = React.useState<OffcanvasProps>({
    show: false,
    placement: 'end'
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<string>('');
  const [selectedServiceProvider, setSelectedServiceProvider] =
    React.useState<TServiceProvider>(initialValue);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilterChange = (event: any) => {
    const { name, value } = event.target;
    setFilter(prevData => ({ ...prevData, [name]: value }));
  };

  const handleResetFilter = () => {
    table.setPageIndex(0);
    setSearchInput('');
    setFilter(initFilter);
    setSelected('');
  };

  const handleOnView = (
    data: TServiceProvider | null,
    show: boolean = true
  ) => {
    setOffCanvas({ offCanvas, ...{ show } });
    if (show) {
      fetchOneServiceProvider(String(data?.id)).catch(e => console.log(e));
    }
  };

  const handleOnEdit = (data: TServiceProvider) => {
    setSelected('update');
    setSelectedServiceProvider(data);
  };

  const handleOnDelete = (data: TServiceProvider) => {
    setLoading(true);
    deleteServiceProvider(Number(data?.id))
      .then(() => {
        toast.success(`Service provider deleted succesfully`);
      })
      .catch(e => {
        toast.error(e?.data?.message || 'Something Went Wrong');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    service_type: Yup.string().required('Service Type is required'),
    status: Yup.string().required('Status is required'),
    service_type_ids: Yup.array()
      .min(1, 'At least one case type must be selected')
      .required('Service type is required')
  });

  const handleSubmit = (formData: TServiceProvider) => {
    if (formData?.id) {
      updateServiceProvider(Number(formData?.id), formData)
        .then(() => {
          setSelected('');
          toast.success(`Service provider updated succesfully`);
        })
        .catch(e => {
          if (e?.status === 422) {
            setSelectedServiceProvider(formData);
          }
          toast.error(e?.data?.message || 'Something Went Wrong');
        });
    } else {
      createServiceProvider(formData)
        .then(() => {
          setSelected('');
          toast.success(`Service provider created succesfully`);
        })
        .catch(e => {
          if (e?.status === 422) {
            setSelectedServiceProvider(formData);
          }
          toast.error(e?.data?.message || 'Something Went Wrong');
        });
    }
  };

  //table
  const table = useAdvanceTable({
    data: serviceProviders,
    columns: serviceProviderColumns({
      onView: handleOnView,
      onEdit: handleOnEdit,
      onDelete: handleOnDelete
    }),
    selection: false,
    sortable: true,
    pagination: true,
    manualPagination: true,
    pageSize: filter.limit,
    pageCount: pageCount(meta?.total_rows || 0, filter.limit),

    onPaginationChange: updater => {
      const newState =
        typeof updater === 'function'
          ? updater(table.getState().pagination)
          : updater;
      table.getState().pagination.pageIndex = newState.pageIndex;
      table.getState().pagination.pageSize = newState.pageSize;
      setFilter({
        ...filter,
        page: newState.pageIndex + 1,
        limit: newState.pageSize
      });
    }
  });

  //useEffect
  React.useEffect(() => {
    setLoading(true);
    fetchAllServiceProviderWithPagination(filter)
      .catch(e => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  }, [JSON.stringify(filter)]);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setFilter({ ...filter, search: searchInput, page: 1 });
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchInput]);

  React.useEffect(() => {
    fetchAllServiceType();
  }, []);

  return (
    <React.Fragment>
      <div className="mb-9">
        <h2 className="mb-5">Service Provider List</h2>
        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  value={searchInput}
                  placeholder="Search service provider"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchInput(event.target.value)
                  }
                />
              </Col>
              <Col xs="auto">
                <Form.Select
                  aria-label="status"
                  name="status"
                  value={filter?.status}
                  onChange={handleFilterChange}
                >
                  <option value="">Status</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Form.Select>
              </Col>
              <Col xs="auto">
                <Form.Select
                  aria-label="service_type"
                  name="service_type"
                  value={filter?.service_type}
                  onChange={handleFilterChange}
                >
                  <option value="">Type</option>
                  <option value="Government">Government</option>
                  <option value="Semi-Government">Semi-Government</option>
                  <option value="Private">Private</option>
                </Form.Select>
              </Col>
              <Col xs="auto">
                <Form.Select
                  aria-label="service_type_ids"
                  name="service_type_ids"
                  value={filter?.service_type_ids}
                  onChange={handleFilterChange}
                >
                  <option value="">Services</option>
                  {serviceTypes?.map((data: TServiceType, index: number) => (
                    <option value={data?.id} key={index}>
                      {data?.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col xs="auto">
                <Button variant="phoenix-danger" onClick={handleResetFilter}>
                  Reset
                </Button>
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button
                  hidden={!checkScope('service-plan-read')}
                  variant="primary"
                  onClick={() => {
                    setSelected('add');
                    setSelectedServiceProvider(initialValue);
                  }}
                >
                  <FontAwesomeIcon icon={faAdd} className="me-2" />
                  Add New
                </Button>
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            {!loading ? <ServiceProviderTable /> : <PhoenixLoader />}
          </div>
        </AdvanceTableProvider>
      </div>

      {/*modal: add or update service provider*/}
      {(selected === 'add' || selected === 'update') && (
        <Formik
          initialValues={selectedServiceProvider}
          validationSchema={validationSchema}
          onSubmit={values => handleSubmit(values)}
        >
          {(formik: FormikProps<TServiceProvider>) => (
            <Form onSubmit={formik.handleSubmit}>
              <ServiceProviderForm
                selected={selected}
                onClose={() => {
                  setSelected('');
                }}
              />
            </Form>
          )}
        </Formik>
      )}

      {/*modal: service provider detail*/}
      <ServiceProviderDetail
        serviceProviderDetail={serviceProviderDetail}
        offCanvas={offCanvas}
        loading={loading}
        onClose={() => {
          handleOnView(serviceProviderDetail, false);
        }}
      />
    </React.Fragment>
  );
};

export default ServiceProviders;
