import { RouteObject } from 'react-router-dom';
import GuestGuard from '../utils/route-guard/GuestGuard';
import SplitSignIn from '../pages/authentication/split/SignIn';
import SplitForgotPassword from '../pages/authentication/split/ForgotPassword';
import SplitResetPassword from '../pages/authentication/split/ResetPassword';

const AuthRoutes: RouteObject = {
  path: '/auth/',
  children: [
    {
      path: 'sign-in',
      element: (
        <GuestGuard>
          <SplitSignIn />
        </GuestGuard>
      )
    },
    {
      path: 'forgot-password',
      element: (
        <GuestGuard>
          <SplitForgotPassword />
        </GuestGuard>
      )
    },
    {
      path: 'reset-password',
      element: (
        <GuestGuard>
          <SplitResetPassword />
        </GuestGuard>
      )
    }
  ]
};

export default AuthRoutes;
