import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Formik } from 'formik';
import { PreIntakeSchema } from '../../../../validation-schema';
import { ageRanges, genders, onBehalfArray } from '../../../../data';
import { TPreIntake } from '../../../../types/intake';
import useFormOptionHook from '../../../../hooks/modules/mdm/useFormOption';
import useCaseTypeHook from '../../../../hooks/modules/mdm/useCaseTypeHook';
import PhoenixLoader from '../../../common/PhoenixLoader';
import PreIntakeLocationForm from './PreIntakeLocationForm';
import ReactSelect from '../../../base/ReactSelect';
import { TReactOption } from '../../../../types';
import { Util } from 'leaflet';
import { useTranslation } from 'react-i18next';
import { formatToSnakeCase } from '../../../../helpers/utils';
import isArray = Util.isArray;

interface PreIntakeFormProps {
  preIntake?: TPreIntake;
  loading: boolean;
  onSubmit: (preIntake: TPreIntake) => void;
}

export interface TPreIntakeForm extends TPreIntake {
  caseOptions?: TReactOption[];
}

const PreIntakeForm = ({
  preIntake,
  onSubmit,
  loading
}: PreIntakeFormProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const { formOptions } = useFormOptionHook();
  const relationships = useMemo(() => {
    return formOptions.filter(data => data.slug == 'relationship');
  }, [formOptions]);
  const { caseTypes } = useCaseTypeHook();
  const [caseTypeOptions, setCaseTypeOptions] = useState<TReactOption[]>([]);
  useEffect(() => {
    setCaseTypeOptions(
      caseTypes.map(data => {
        return { label: `${data.name} - ${data.code}`, value: `${data.id}` };
      })
    );
  }, [caseTypes]);

  const initialValues: TPreIntakeForm = useMemo(() => {
    let caseOptions: TReactOption[] = [];
    if (preIntake) {
      if (preIntake?.case_type_id) {
        const caseTypeIds = JSON.parse(preIntake.case_type_id) || [];
        if (isArray(caseTypeIds)) {
          caseOptions = caseTypeOptions.filter(data =>
            caseTypeIds.includes(parseInt(data.value as string))
          );
        }
      }
      return {
        ...preIntake,
        caseOptions: caseOptions
      };
    } else {
      return {
        individual_relationship: undefined,
        individual_gender: 'M',
        province: undefined,
        district: undefined,
        individual_first_name: '',
        individual_last_name: '',
        individual_address: '',
        individual_phone_number: '',
        individual_email: '',
        on_behalf: 'myself',
        caseOptions: caseOptions
      };
    }
  }, [preIntake, caseTypeOptions]);
  const handleOnSubmit = (values: TPreIntakeForm) => {
    const caseOptions =
      values.caseOptions?.map(data => parseInt(data.value as string)) || [];
    delete values.caseOptions;
    const updatedValues: TPreIntakeForm = {
      ...values,
      case_type_id: JSON.stringify(caseOptions)
    };

    // console.log(updatedValues);
    onSubmit(updatedValues);
  };

  return (
    <>
      {!loading ? (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={PreIntakeSchema}
          onSubmit={handleOnSubmit}
        >
          {({
            values,
            setFieldValue,
            handleBlur,
            handleChange,
            errors,
            touched,
            handleSubmit
          }) => {
            return (
              <Form noValidate onSubmit={handleSubmit}>
                <h5>{t('i_am_applying_for')}</h5>
                <div className="d-flex gap-3 mt-3">
                  {onBehalfArray.map(data => (
                    <Form.Check
                      inline
                      type="radio"
                      name="on_behalf"
                      value={data.value || 'myself'}
                      key={`onBehalf-${data.value}`}
                      id={`onBehalf-${data.value}`}
                      label={t(formatToSnakeCase(data.label))}
                      defaultChecked={data.value === values.on_behalf}
                      className="me-2"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  ))}
                </div>
                {values.on_behalf == 'others' && (
                  <Form.Group className="mb-3">
                    <Form.Label>{t('relationship')}</Form.Label>
                    <Form.Select
                      className={`${
                        touched.individual_relationship &&
                        errors.individual_relationship
                          ? 'is-invalid'
                          : ''
                      }`}
                      value={values?.individual_relationship || ''}
                      name="individual_relationship"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        {t('select_relationship')}
                      </option>
                      {relationships.map((data, i) => (
                        <option
                          key={`relationship-${i}`}
                          value={data.id as number}
                        >
                          {data.label}
                        </option>
                      ))}
                    </Form.Select>
                    {touched.individual_relationship &&
                      errors.individual_relationship && (
                        <Form.Control.Feedback type="invalid">
                          {errors.individual_relationship}
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>
                )}

                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formGroupFirstName">
                      <Form.Label>{t('first_name')}</Form.Label>
                      <Form.Control
                        className={`${
                          touched.individual_first_name &&
                          errors.individual_first_name
                            ? 'is-invalid'
                            : ''
                        }`}
                        type="text"
                        name="individual_first_name"
                        placeholder={t('enter_your_first_name')}
                        value={values.individual_first_name || ''}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.individual_first_name &&
                        errors.individual_first_name && (
                          <Form.Control.Feedback type="invalid">
                            {errors.individual_first_name}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formGroupLastName">
                      <Form.Label>{t('last_name')}</Form.Label>
                      <Form.Control
                        className={`${
                          touched.individual_last_name &&
                          errors.individual_last_name
                            ? 'is-invalid'
                            : ''
                        }`}
                        type="text"
                        placeholder={t('enter_your_last_name')}
                        value={values.individual_last_name || ''}
                        name="individual_last_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />

                      {touched.individual_last_name &&
                        errors.individual_last_name && (
                          <Form.Control.Feedback type="invalid">
                            {errors.individual_last_name}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </Col>
                </Row>

                <div className="d-flex flex-column gap-3 mt-3">
                  <h5>
                    {values.on_behalf == 'group'
                      ? t('your_representative_details')
                      : t('your_contact_details')}
                  </h5>
                  <Row>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formGroupFirstName"
                      >
                        <Form.Label>{t('phone_number')}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t('example_phone_number')}
                          className={`${
                            touched.individual_phone_number &&
                            errors.individual_phone_number
                              ? 'is-invalid'
                              : ''
                          }`}
                          value={values.individual_phone_number || ''}
                          name="individual_phone_number"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.individual_phone_number &&
                          errors.individual_phone_number && (
                            <Form.Control.Feedback type="invalid">
                              {errors.individual_phone_number}
                            </Form.Control.Feedback>
                          )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formGroupLastName"
                      >
                        <Form.Label>{t('current_address')}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t('example_address')}
                          className={`${
                            touched.individual_address &&
                            errors.individual_address
                              ? 'is-invalid'
                              : ''
                          }`}
                          value={values.individual_address || ''}
                          name="individual_address"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.individual_address &&
                          errors.individual_address && (
                            <Form.Control.Feedback type="invalid">
                              {errors.individual_address}
                            </Form.Control.Feedback>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group controlId="formGroupEmail">
                        <Form.Label>{t('email_address')}</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder={t('example_email_address')}
                          className={`${
                            touched.individual_email && errors.individual_email
                              ? 'is-invalid'
                              : ''
                          }`}
                          value={values.individual_email || ''}
                          name="individual_email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.individual_email &&
                          errors.individual_email && (
                            <Form.Control.Feedback type="invalid">
                              {errors.individual_email}
                            </Form.Control.Feedback>
                          )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('gender')}</Form.Label>
                        <Form.Select
                          value={values?.individual_gender || ''}
                          name="individual_gender"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {genders.map((data, i) => (
                            <option
                              key={`individual_gender-${i}`}
                              value={data.value}
                            >
                              {data.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('age_range_optional')}</Form.Label>
                        <Form.Select
                          value={values?.individual_age_range || ''}
                          name="individual_age_range"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <option value="" disabled>
                            {t('select_range_age')}
                          </option>
                          {ageRanges.map((data, i) => (
                            <option key={`ageRange-${i}`} value={data.value}>
                              {data.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="formGroupCaseType"
                      >
                        <Form.Label>{t('case_type_optional')}</Form.Label>

                        <ReactSelect
                          options={caseTypeOptions}
                          isMulti
                          name="caseOptions"
                          onBlur={handleBlur}
                          value={values?.caseOptions || []}
                          onChange={options => {
                            setFieldValue('caseOptions', options);
                          }}
                          placeholder={t('select_case_type')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr />
                  <PreIntakeLocationForm preIntake={preIntake} />
                  <hr />
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="reasons">
                        <Form.Label>
                          {t('reason_for_applying_optional')}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder={t('reason_for_applying_optional')}
                          value={values.reason_for_apply || ''}
                          name="reason_for_apply"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Button
                        disabled={loading}
                        type="submit"
                        variant="success"
                        className="rounded-pill w-100 mb-4"
                      >
                        <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
                        {preIntake?.id ? t('save') : t('submit_request')}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <PhoenixLoader />
      )}
    </>
  );
};

export default PreIntakeForm;
