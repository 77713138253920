import { createSlice } from '@reduxjs/toolkit';
import { TPreIntakeHistory } from '../../../types/intake';

interface PreIntakeHistoryState {
  preIntakeHistories: TPreIntakeHistory[];
  preIntakeHistory: TPreIntakeHistory;
}

const initialState: PreIntakeHistoryState = {
  preIntakeHistories: [],
  preIntakeHistory: <TPreIntakeHistory>{}
};

const preIntakeHistorySlice = createSlice({
  name: 'preIntakeHistory',
  initialState,
  reducers: {
    getAllPreIntakeHistory(state, action) {
      state.preIntakeHistories = action.payload.results || [];
    },
    getOnePreIntakeHistory(state, action) {
      state.preIntakeHistory = action.payload.row || null;
    },
    createOnePreIntakeHistory(state, action) {
      state.preIntakeHistories = [
        ...state.preIntakeHistories,
        action.payload.row
      ];
    },
    updateOnePreIntakeHistory(state, action) {
      state.preIntakeHistories = state.preIntakeHistories.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },

    removeOnePreIntakeHistory(state, action) {
      state.preIntakeHistories = state.preIntakeHistories.filter(
        item => item.id !== action.payload.id
      );
    }
  }
});

export const {
  getAllPreIntakeHistory,
  getOnePreIntakeHistory,
  createOnePreIntakeHistory,
  updateOnePreIntakeHistory,
  removeOnePreIntakeHistory
} = preIntakeHistorySlice.actions;
export default preIntakeHistorySlice.reducer;
