import { createSlice } from '@reduxjs/toolkit';
import { TMeta } from 'types';
import { TCaseEvaluation } from 'types/evaluation';

interface CaseEvaluationState {
  evaluations: TCaseEvaluation[];
  evaluation: TCaseEvaluation | null;
  meta: TMeta;
}

const initialState: CaseEvaluationState = {
  evaluations: [],
  evaluation: <TCaseEvaluation>{},
  meta: <TMeta>{}
};

const evaluationSlice = createSlice({
  name: 'caseEvaluation',
  initialState,
  reducers: {
    getAllEvaluations(state, action) {
      state.evaluations = action.payload.results || [];
      state.meta = action.payload.meta || {};
    },
    getOneEvaluation(state, action) {
      state.evaluation = action.payload.row || null;
    },
    createOneEvaluation(state, action) {
      state.evaluations = [...state.evaluations, action.payload.row];
    }
  }
});

export const { getAllEvaluations, getOneEvaluation, createOneEvaluation } =
  evaluationSlice.actions;
export default evaluationSlice.reducer;
