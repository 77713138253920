import { createSlice } from '@reduxjs/toolkit';
import { TCaseType } from '../../../types/mdm';

interface CaseTypeState {
  caseTypes: TCaseType[];
  caseType: TCaseType;
}

const initialState: CaseTypeState = {
  caseTypes: [],
  caseType: <TCaseType>{}
};

const caseTypeSlice = createSlice({
  name: 'caseType',
  initialState,
  reducers: {
    getAllCaseType(state, action) {
      state.caseTypes = action.payload.results || [];
    },
    getOneCaseType(state, action) {
      state.caseType = action.payload.row || null;
    },
    createOneCaseType(state, action) {
      state.caseTypes = [...state.caseTypes, action.payload.row];
    },
    updateOneCaseType(state, action) {
      state.caseTypes = state.caseTypes.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },

    removeOneCaseType(state, action) {
      state.caseTypes = state.caseTypes.filter(
        item => item.id !== action.payload.id
      );
    }
  }
});

export const {
  getAllCaseType,
  getOneCaseType,
  createOneCaseType,
  updateOneCaseType,
  removeOneCaseType
} = caseTypeSlice.actions;
export default caseTypeSlice.reducer;
