import React, { useMemo } from 'react';
import { TPreIntake } from '../../../../types/intake';
import { Col, Row } from 'react-bootstrap';
import Badge from '../../../../components/base/Badge';
import PhoenixLoader from '../../../../components/common/PhoenixLoader';
import useFormOptionHook from '../../../../hooks/modules/mdm/useFormOption';
import useCaseTypeHook from '../../../../hooks/modules/mdm/useCaseTypeHook';
import useLocationHook from '../../../../hooks/modules/mdm/useLocationHook';
import { Util } from 'leaflet';
import { useTranslation } from 'react-i18next';
import StatusBadge from '../../../../components/common/StatusBadge';
import isArray = Util.isArray;

interface PreIntakeDetailInValidationProps {
  preIntake: TPreIntake;

  loading: boolean;
}

const PreIntakeDetailInValidation = ({
  loading,
  preIntake
}: PreIntakeDetailInValidationProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const { formOptions } = useFormOptionHook();
  const { locations } = useLocationHook();
  const relationships = useMemo(() => {
    return formOptions.filter(data => data.slug == 'relationship');
  }, [formOptions]);
  const { caseTypes } = useCaseTypeHook();
  const caseNames = useMemo(() => {
    let cases: string[] = [];
    if (preIntake?.case_type_id) {
      const caseTypeIds = JSON.parse(preIntake.case_type_id) || [];
      if (isArray(caseTypeIds)) {
        cases = caseTypes
          .filter(data => caseTypeIds.includes(data.id as number))
          .map(data => `${data?.name} - ${data?.code} `);
      }
    }
    return cases;
  }, [preIntake?.case_type_id, caseTypes]);

  const relationName = useMemo(() => {
    return (
      relationships.filter(
        data => data.id == (preIntake.individual_relationship as number)
      )?.[0]?.label || ''
    );
  }, [relationships, preIntake]);
  const locationName = useMemo(() => {
    return {
      province:
        locations.filter(data => data.id == (preIntake.province as number))?.[0]
          ?.name || '',
      district:
        locations.filter(data => data.id == (preIntake.district as number))?.[0]
          ?.name || '',
      commune:
        locations.filter(data => data.id == (preIntake.commune as number))?.[0]
          ?.name || '',
      village:
        locations.filter(data => data.id == (preIntake.village as number))?.[0]
          ?.name || ''
    };
  }, [caseTypes, preIntake]);
  return (
    <>
      {!loading ? (
        <>
          <div className="mb-3">
            <Badge
              pill
              bg="success"
              variant="phoenix"
              className="border-0 p-1 me-2"
            >
              {t('on_behalf_of')}{' '}
              {preIntake?.on_behalf ? t(preIntake.on_behalf) : ''}
            </Badge>

            <StatusBadge status={preIntake?.status as string} />
          </div>
          <div className="mt-4">
            <Row>
              <Col md={4}>
                <h6> {t('relationship')}</h6>
                <span>{relationName || 'N/A'}</span>
              </Col>
              <Col md={4}>
                <h6>{t('address')}</h6>
                <span>{preIntake?.individual_address || 'N/A'}</span>
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <Row>
              <Col md={4}>
                <h6> {t('province')}</h6>
                <span>{locationName?.province || 'N/A'}</span>
              </Col>
              <Col md={4}>
                <h6> {t('district')}</h6>
                <span>{locationName?.district || 'N/A'}</span>
              </Col>

              <Col md={4}>
                <h6> {t('commune')}</h6>
                <span>{locationName?.commune || 'N/A'}</span>
              </Col>
              <Col md={4}>
                <h6> {t('village')}</h6>
                <span>{locationName?.village || 'N/A'}</span>
              </Col>
            </Row>
          </div>

          <div className="mt-4">
            <h4 className="mb-2">
              {' '}
              <h6> {t('reason_for_applying')}</h6>
            </h4>
            <p>{preIntake?.reason_for_apply || 'N/A'}</p>
          </div>
          <div className="mt-4">
            <h4 className="mb-2">
              {' '}
              <h6> {t('case_type')}</h6>
            </h4>
            {caseNames.map((name, i) => (
              <Badge
                key={`caseNames-${i}`}
                bg="secondary"
                variant="phoenix"
                className="border-0 me-2"
              >
                {name}
              </Badge>
            ))}
          </div>
        </>
      ) : (
        <PhoenixLoader />
      )}
    </>
  );
};
export default PreIntakeDetailInValidation;
