import { Col, Offcanvas, OffcanvasProps, Row, Stack } from 'react-bootstrap';
import { TServiceProvider, TServiceType } from 'types/service-provider';
import Badge from 'components/base/Badge';
import {
  CallIcon,
  CheckmarkCircle01Icon,
  Location05Icon,
  Mail02Icon
} from 'hugeicons-react';
import PhoenixLoader from 'components/common/PhoenixLoader';

export interface ServiceProviderDetailProps {
  serviceProviderDetail: TServiceProvider | null;
  onClose: () => void;
  offCanvas: OffcanvasProps;
  loading?: boolean;
}

const ServiceProviderDetail = ({
  serviceProviderDetail,
  onClose,
  offCanvas,
  loading
}: ServiceProviderDetailProps) => {
  return (
    <Offcanvas {...offCanvas} placement="end" onHide={onClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Service Provider Detail</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {!loading ? (
          <div>
            <Stack direction="horizontal" gap={1} className="mb-4">
              <Badge
                pill
                bg="secondary"
                variant="phoenix"
                className="border-0 p-1 fw-medium text-capitalize"
              >
                {serviceProviderDetail?.service_type}
              </Badge>
            </Stack>
            <h3 className="mb-3">{serviceProviderDetail?.name}</h3>
            <Stack gap={4}>
              <Stack gap={2}>
                <Row className="g-3">
                  <Col md={4}>
                    <div>
                      <CallIcon
                        size={14}
                        className="me-2 text-body-quaternary"
                      />
                      <span>Phone</span>
                    </div>
                  </Col>
                  <Col md={8}>
                    <span>{serviceProviderDetail?.phone_number}</span>
                  </Col>
                </Row>
                <Row className="g-3">
                  <Col md={4}>
                    <div>
                      <Mail02Icon
                        size={14}
                        className="me-2 text-body-quaternary"
                      />
                      <span>Email</span>
                    </div>
                  </Col>
                  <Col md={8}>
                    <span>{serviceProviderDetail?.email}</span>
                  </Col>
                </Row>
                <Row className="g-3">
                  <Col md={4}>
                    <div>
                      <Location05Icon
                        size={14}
                        className="me-2 text-body-quaternary"
                      />
                      <span>Address</span>
                    </div>
                  </Col>
                  <Col md={8}>
                    <span>{serviceProviderDetail?.address}</span>
                  </Col>
                </Row>
                <Row className="g-3">
                  <Col md={4}>
                    <div>
                      <CheckmarkCircle01Icon
                        size={14}
                        className="me-2 text-body-quaternary"
                      />
                      <span>Status</span>
                    </div>
                  </Col>
                  <Col md={8}>
                    <Badge
                      pill
                      bg={`${serviceProviderDetail?.status?.toLowerCase() === 'active' ? 'success' : 'secondary'}`}
                      variant="phoenix"
                      className="border-0 p-1 fw-medium text-capitalize"
                    >
                      {serviceProviderDetail?.status?.toLowerCase() === 'active'
                        ? 'Active'
                        : 'Inactive'}
                    </Badge>
                  </Col>
                </Row>
              </Stack>
              <Stack gap={1}>
                <h5>Services</h5>
                <Stack direction="horizontal" gap={1}>
                  {serviceProviderDetail?.service_types?.map(
                    (data: TServiceType, index: number) => (
                      <Badge
                        key={index}
                        bg="secondary"
                        variant="phoenix"
                        className="border-0 p-1 fw-medium"
                      >
                        {data?.name}
                      </Badge>
                    )
                  )}
                </Stack>
              </Stack>
              <Stack gap={1}>
                <h5>Area Coverage</h5>
                <p className="mb-0">{serviceProviderDetail?.area_coverage}</p>
              </Stack>
              <Stack gap={1}>
                <h5>Notes</h5>
                <p>{serviceProviderDetail?.note}</p>
              </Stack>
            </Stack>
          </div>
        ) : (
          <PhoenixLoader />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ServiceProviderDetail;
