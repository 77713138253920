import { TServiceProvider, TServiceType } from 'types/service-provider';
import { ColumnDef } from '@tanstack/react-table';
import ActionTableItems from 'components/common/ActionTableItems';
import { checkScope } from 'helpers/auth';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

interface serviceProviderTableColumnsProps {
  onView?: (data: TServiceProvider, show: boolean) => void;
  onEdit?: (data: TServiceProvider) => void;
  onDelete?: (data: TServiceProvider) => void;
}

export const serviceProviderColumns = ({
  onView,
  onEdit,
  onDelete
}: serviceProviderTableColumnsProps) => {
  const tempColumns: ColumnDef<TServiceProvider>[] = [
    {
      accessorKey: 'name',
      header: 'Name',
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'sort' }
      }
    },
    {
      accessorKey: 'email',
      header: 'Email',
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'sort' }
      }
    },
    {
      accessorKey: 'registered_date',
      header: 'Registered Date',
      meta: {
        headerProps: { style: { width: '15%' } },
        cellProps: { className: 'sort' }
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ getValue }) => {
        const status = String(getValue());
        return (
          <div className="d-flex gap-1">
            <span
              className={`border-0 badge-phoenix badge p-1 ${
                status?.toLowerCase() === 'active'
                  ? 'badge-phoenix-success'
                  : 'badge-phoenix-secondary'
              }`}
            >
              {status}
            </span>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '15%' } },
        cellProps: { className: 'sort' }
      }
    },
    {
      accessorKey: 'service_type',
      header: 'Type',
      meta: {
        headerProps: { style: { width: '15%' } },
        cellProps: { className: 'sort' }
      }
    },
    {
      accessorKey: 'service_types',
      header: 'Services',
      cell: ({ getValue }) => {
        const serviceTypes = getValue() as Array<TServiceType> | undefined;

        if (!Array.isArray(serviceTypes)) {
          return;
        }

        return (
          <div className="d-flex gap-2">
            {serviceTypes?.map((data: TServiceType, index: number) => (
              <div className="d-flex gap-1" key={index}>
                <span
                  className={`border-0 badge-phoenix badge p-1 badge-phoenix-secondary`}
                >
                  {data?.name}
                </span>
              </div>
            ))}
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '15%' } },
        cellProps: { className: 'sort' }
      }
    },
    {
      accessorKey: 'area_coverage',
      header: 'Area Coverage',
      meta: {
        headerProps: { style: { width: '30%' } },
        cellProps: { className: 'sort' }
      }
    },
    {
      header: `Action`,
      id: 'action',
      cell: ({ row: { original } }) => {
        const row = original;

        return (
          <ActionTableItems
            data={row}
            onView={
              checkScope('service-plan-read')
                ? () => onView?.(row, true)
                : undefined
            }
            onEdit={
              checkScope('service-plan-update')
                ? () => onEdit?.(row)
                : undefined
            }
            onDelete={
              checkScope('service-plan-delete')
                ? () => onDelete?.(row)
                : undefined
            }
          />
        );
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];
  return tempColumns;
};

const ServiceProviderTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default ServiceProviderTable;
