import api from '../../utils/api';
import { serializedObject } from 'helpers/utils';
import { TServiceAssessment } from 'types/assessment';
import {
  TServiceCategory,
  TFilterServiceProvider
} from 'types/service-provider';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/case/assessment/service-providers`;

const fetchAll = () => {
  return api.get(`${authEndpoint}`);
};

const fetchAllWithPagination = (filters: TFilterServiceProvider) => {
  const { service_type_ids, ...filteredFilters } = filters;
  return api.get(
    `${authEndpoint}/listAdvance?${serializedObject(filteredFilters)}&service_type_ids[]=${serializedObject(service_type_ids)}`
  );
};

const fetchAllGroupServiceProvider = (
  caseId: string,
  filters: { [key: string]: string }
) => {
  return api.get(
    `${process.env.REACT_APP_ENDPOINT}/case/assessment/service-plans/group-service-provider/${caseId}?${serializedObject(filters)}`
  );
};

const fetchServiceProvidersWithIssueCategory = (data: TServiceCategory) => {
  return api.post(
    `${process.env.REACT_APP_ENDPOINT}/case/assessment/list-category-service-providers`,
    data
  );
};

const fetchOne = (id: string) => {
  return api.get(`${authEndpoint}/${id}`);
};

const create = (data: TServiceAssessment) => {
  return api.post(`${authEndpoint}`, data);
};

const update = (id: number, data: TServiceAssessment) => {
  return api.put(`${authEndpoint}/${id}`, data);
};

const destroy = (id: number) => {
  return api.delete(`${authEndpoint}/${id}`);
};

export default {
  fetchAll,
  fetchAllWithPagination,
  fetchAllGroupServiceProvider,
  fetchServiceProvidersWithIssueCategory,
  fetchOne,
  create,
  update,
  destroy
};
