import { RouteObject } from 'react-router-dom';
import GuestGuard from '../utils/route-guard/GuestGuard';
import RegisterCase from '../pages/public/register';
import TrackMyCase from '../pages/public/track-my-case';
import TrackResults from '../pages/public/track-results';

const PublicRoutes: RouteObject = {
  path: '/',
  children: [
    {
      path: '/',
      children: [
        {
          path: '/',
          element: (
            <GuestGuard>
              <RegisterCase />
            </GuestGuard>
          )
        }
      ]
    },
    {
      path: '/track-my-case',
      children: [
        {
          path: '/track-my-case',
          element: (
            <GuestGuard>
              <TrackMyCase />
            </GuestGuard>
          )
        }
      ]
    },
    {
      path: '/track-results',
      children: [
        {
          path: '/track-results',
          element: (
            <GuestGuard>
              <TrackResults />
            </GuestGuard>
          )
        }
      ]
    }
  ]
};

export default PublicRoutes;
