import * as Yup from 'yup';

export const UserUpdateSchema = Yup.object().shape({
  name: Yup.string()
    .required('The Name field is required.')
    .min(3, 'Name must be at least 8 characters')
    .max(20, 'Name must be less  than 20 characters'),
  email: Yup.string().required('The Email field is required.').email()
});

export const UserChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required(
    'The Current Password field is required.'
  ),
  newPassword: Yup.string()
    .min(6, 'New Password too short!')
    .required('The New Password field is required.'),
  confirmPassword: Yup.string()
    .min(6, 'New Password too short!')
    .required('The Confirm Password field is required.')
    .oneOf([Yup.ref('newPassword')], 'The new password doses not match.')
});

export const SignInSchema = Yup.object().shape({
  username: Yup.string().required('The Username field is required.'),
  password: Yup.string()
    .min(6, 'Password too short!')
    .required('The Password field is required.')
});
