import api from '../../utils/api';
import { TPreIntakeHistory } from '../../types/intake';
import axios from 'axios';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/case/intake/pre-intake/history`;

const fetchAll = (preIntakeId: number) => {
  return api.get(`${authEndpoint}/${preIntakeId}`);
};
const fetchOne = (id: number) => {
  return api.get(`${authEndpoint}/${id}`);
};
const create = (data: TPreIntakeHistory) => {
  return axios.post(`${authEndpoint}`, data);
};
const update = (id: number, data: TPreIntakeHistory) => {
  return api.put(`${authEndpoint}/${id}`, data);
};
const destroy = (id: number) => {
  return api.delete(`${authEndpoint}/${id}`);
};

export default {
  fetchAll,
  fetchOne,
  create,
  update,
  destroy
};
