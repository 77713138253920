import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const defaultNS = 'translation';
const fallbackLng = ['en'];
// const availableLanguages = ['en', 'kh'];

// eslint-disable-next-line
i18n

  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: cookies.get('i18next') || 'km',
    detection: {
      order: ['cookie', 'path', 'htmlTag'],
      caches: ['cookie']
    },
    backend: {
      loadPath: `/assets/i18n/{{ns}}/{{lng}}.json`
    },
    fallbackLng,
    // whitelist: availableLanguages,
    debug: true, //false to turn off console warnings

    defaultNS,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: '.'
    },
    // react: {
    //     wait: true,
    // },
    // eslint-disable-next-line
    returnEmptyString: false
  });

export default i18n;
