import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon, UilFileSearchAlt, UilUsersAlt } from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  permission?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  next?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  permission?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'my_cases',
    icon: UilFileSearchAlt,
    active: true,
    permission: 'pre-intake-list,case-list',
    pages: [
      {
        name: 'pre_intake_list',
        iconSet: 'font-awesome',
        icon: '',
        path: '/pre-intake',
        pathName: 'pre-intake',
        permission: 'pre-intake-list',
        active: true
      },
      {
        name: 'case_list',
        iconSet: 'font-awesome',
        icon: '',
        path: '/case',
        pathName: 'case',
        permission: 'case-list',
        active: true
      }
    ]
  },
  {
    label: 'users',
    icon: UilUsersAlt,
    permission: 'auth-user-list,auth-role-list',
    pages: [
      {
        name: 'user',
        path: '/user-management/user',
        pathName: 'user',
        permission: 'auth-user-list',
        active: true
      },
      {
        name: 'role',
        path: '/user-management/role',
        pathName: 'role',
        permission: 'auth-role-list',
        active: true
      }
    ]
  }
];
