import { createSlice } from '@reduxjs/toolkit';
import { TDashboard } from 'types/dashboard';

interface DashboardState {
  dashboard: TDashboard | null;
}

const initialState: DashboardState = {
  dashboard: <TDashboard>{}
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getOneDashboard(state, action) {
      state.dashboard = action.payload.row || null;
    }
  }
});

export const { getOneDashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer;
