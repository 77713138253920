import { Card, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDay,
  faClock,
  faClockRotateLeft
} from '@fortawesome/free-solid-svg-icons';
import emptyIcon from '../../../../assets/img/empty-icon.png';
import React from 'react';
import { TPreIntakeHistory } from '../../../../types/intake';
import PhoenixLoader from '../../../../components/common/PhoenixLoader';
import {
  convertTimeDate,
  convertTimeToHumanTime
} from '../../../../helpers/date';
import { useTranslation } from 'react-i18next';

interface PreIntakeHistoryProps {
  preIntakeHistories: TPreIntakeHistory[];

  loading: boolean;
}

const PreIntakeHistory = ({
  loading,
  preIntakeHistories
}: PreIntakeHistoryProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  return (
    <>
      <Card className="border-0 shadow rounded-3 mb-5">
        <Card.Body>
          {!loading ? (
            <>
              <div className="d-flex  align-items-center justify-content-between mb-3">
                <h3>{t('history')}</h3>
                <div
                  className="d-flex bg-primary-subtle rounded-circle bg-danger flex-center"
                  style={{ width: '32px', height: '32px' }}
                >
                  <FontAwesomeIcon
                    icon={faClockRotateLeft}
                    className="fs-9 text-primary"
                  />
                </div>
              </div>
              {preIntakeHistories.length > 0 ? (
                <Stack>
                  {preIntakeHistories.map((data, i) => (
                    <div key={`preIntakeHistories-${i}`}>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex gap-2 flex-column">
                          <div>
                            <h5 className="mb-1">{data?.title}</h5>
                            <span>{data?.description}</span>
                          </div>
                          <Stack direction="horizontal" gap={3}>
                            <div>
                              <FontAwesomeIcon
                                icon={faCalendarDay}
                                className="me-2 text-body-quaternary fs-9"
                              />
                              <span>
                                {' '}
                                {data?.created_at
                                  ? convertTimeDate(data.created_at)
                                  : 'N/A'}{' '}
                              </span>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faClock}
                                className="me-2 text-body-quaternary fs-9"
                              />
                              <span>
                                {' '}
                                {data?.created_at
                                  ? convertTimeToHumanTime(data.created_at)
                                  : 'N/A'}{' '}
                              </span>
                            </div>
                          </Stack>
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))}
                </Stack>
              ) : (
                <Stack gap={3} className="text-center">
                  <div>
                    <img width="130" src={emptyIcon} />
                  </div>
                  <p>{t('no_entries_yet_to_show')}</p>
                </Stack>
              )}
            </>
          ) : (
            <PhoenixLoader />
          )}
        </Card.Body>
      </Card>
    </>
  );
};
export default PreIntakeHistory;
