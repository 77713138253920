import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import userReducer from './user/userSlice';
import roleReducer from './user/roleSlice';
import preIntakeReducer from './intake/preIntakeSlice';
import preIntakeValidationReducer from './intake/preIntakeValidationSlice';
import preIntakeHistoryReducer from './intake/preIntakeHistorySlice';
import locationReducer from './mdm/locationSlice';
import caseTypeReducer from './mdm/caseTypeSlice';
import formOptionReducer from './mdm/formOptionSlice';
import caseReducer from './case/caseSlice';
import caseAssessmentSlice from './case/assessmentSlice';
import roleOptionSlice from './auth/roleOptionSlice';
import serviceProviderSlice from './service/serviceProviderSlice';
import caseEvaluationSlice from './case/evaluationSlice';
import serviceTypeSlice from './service/serviceTypeSlice';
import dashboardSlice from './dashboardSlice';
import executiveUnitAddressSlice from './executiveUnitAddressSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  role: roleReducer,
  location: locationReducer,
  caseType: caseTypeReducer,
  preIntake: preIntakeReducer,
  preIntakeValidation: preIntakeValidationReducer,
  preIntakeHistory: preIntakeHistoryReducer,
  case: caseReducer,
  formOption: formOptionReducer,
  caseAssessment: caseAssessmentSlice,
  roleOption: roleOptionSlice,
  serviceProvider: serviceProviderSlice,
  caseEvaluation: caseEvaluationSlice,
  serviceType: serviceTypeSlice,
  executiveUnitAddress: executiveUnitAddressSlice,
  dashboard: dashboardSlice
});

export default rootReducer;
