import { createSlice } from '@reduxjs/toolkit';
import { TMeta } from 'types';
import { TRoleOption } from 'types/auth/role';

interface RoleOptionState {
  roleOptions: TRoleOption[];
  meta: TMeta;
}

const initialState: RoleOptionState = {
  roleOptions: [],
  meta: <TMeta>{}
};

const roleOptionSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    getAllRoleOption(state, action) {
      state.roleOptions = action.payload.results || [];
      state.meta = action.payload.meta || {};
    }
  }
});

export const { getAllRoleOption } = roleOptionSlice.actions;
export default roleOptionSlice.reducer;
