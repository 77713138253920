import { RouteObject } from 'react-router-dom';
import MainLayoutProvider from '../providers/MainLayoutProvider';
import AuthGuard from '../utils/route-guard/AuthGuard';
import MainLayout from '../layouts/MainLayout';
import { lazy, Suspense } from 'react';
import PhoenixLoader from '../components/common/PhoenixLoader';

const Case = lazy(() => import('pages/case'));
const CaseDetail = lazy(() => import('pages/case/detail'));
const CaseIntake = lazy(() => import('pages/case/intake'));
const CaseAssessment = lazy(() => import('pages/case/assessment'));
const CaseServicePlan = lazy(() => import('pages/case/service-plan'));
const CaseReferralAdmission = lazy(
  () => import('pages/case/referral-admission')
);

const CaseMonitoringOversight = lazy(
  () => import('pages/case/monitoring-oversight')
);
const CaseEvaluation = lazy(() => import('pages/case/evaluation'));

const CaseRoutes: RouteObject = {
  path: '/case',
  element: (
    <MainLayoutProvider>
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    </MainLayoutProvider>
  ),
  children: [
    {
      path: '',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <Case />
        </Suspense>
      )
    },
    {
      path: 'detail/:caseId',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <CaseDetail />
        </Suspense>
      )
    },
    {
      path: 'intake/:caseId',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <CaseIntake />
        </Suspense>
      )
    },
    {
      path: 'assessment/:caseId',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <CaseAssessment />
        </Suspense>
      )
    },
    {
      path: 'service_plan/:caseId',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <CaseServicePlan />
        </Suspense>
      )
    },
    {
      path: 'referral_and_admission/:caseId',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <CaseReferralAdmission />
        </Suspense>
      )
    },
    {
      path: 'monitoring_and_oversight/:caseId',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <CaseMonitoringOversight />
        </Suspense>
      )
    },
    {
      path: 'evaluation/:caseId',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <CaseEvaluation />
        </Suspense>
      )
    }
  ]
};

export default CaseRoutes;
