import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { TPreIntake } from '../../../../types/intake';
import useLocationHook from '../../../../hooks/modules/mdm/useLocationHook';
import { TLocation } from '../../../../types/mdm';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

interface PreIntakeLocationFormProps {
  preIntake?: TPreIntake;
}

const PreIntakeLocationForm = ({ preIntake }: PreIntakeLocationFormProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const { values, setFieldValue, handleBlur, errors, touched } =
    useFormikContext<TPreIntake>();
  const { fetchAllLocationWithTree, treeLocations } = useLocationHook();
  useEffect(() => {
    if (treeLocations.length <= 0) {
      fetchAllLocationWithTree().catch(e => console.log(e));
    }
  }, []);
  const [locationState, setLocationState] = useState({
    provinces: [] as TLocation[],
    districts: [] as TLocation[],
    communes: [] as TLocation[],
    villages: [] as TLocation[]
  });
  useEffect(() => {
    setLocationState(prev => ({
      ...prev,
      provinces: treeLocations.filter(data => !data.parent_id)
    }));
  }, [treeLocations]);
  useEffect(() => {
    setLocationState(prev => ({
      ...prev,
      districts:
        locationState.provinces.filter(
          data => data.id == preIntake?.province
        )?.[0]?.children || []
    }));
  }, [preIntake?.province, locationState.provinces]);

  useEffect(() => {
    setLocationState(prev => ({
      ...prev,
      communes:
        locationState.districts.filter(
          data => data.id == preIntake?.district
        )?.[0]?.children || []
    }));
  }, [preIntake?.district, locationState.districts]);
  useEffect(() => {
    setLocationState(prev => ({
      ...prev,
      villages:
        locationState.communes.filter(
          data => data.id == preIntake?.commune
        )?.[0]?.children || []
    }));
  }, [preIntake?.commune, locationState.communes]);
  const updateLocation = (
    id: string,
    parentLevel: keyof typeof locationState,
    childLevel: keyof typeof locationState
  ) => {
    const children = id
      ? locationState[parentLevel]?.find(data => data.id === parseInt(id))
          ?.children || []
      : [];

    setLocationState(prevState => ({
      ...prevState,
      [childLevel]: children,
      ...(childLevel === 'provinces' && {
        districts: [],
        communes: [],
        villages: []
      }),
      ...(childLevel === 'districts' && { communes: [], villages: [] }),
      ...(childLevel === 'communes' && { villages: [] })
    }));
  };

  return (
    <Row>
      <Col md={6}>
        <Form.Group className="mb-3" controlId="formGroupProvince">
          <Form.Label>{t('province')}</Form.Label>
          <Form.Select
            value={values?.province || ''}
            name="province"
            onBlur={handleBlur}
            onChange={e => {
              const selectedId = e.target.value;
              setFieldValue('province', selectedId);
              updateLocation(selectedId, 'provinces', 'districts');
            }}
            className={`${
              touched.province && errors.province ? 'is-invalid' : ''
            }`}
          >
            <option value="">{t('select_province')}</option>

            {locationState.provinces.map((data, i) => (
              <option key={`province-${i}`} value={data.id as number}>
                {`${data.name} - ${data.code}`}
              </option>
            ))}
          </Form.Select>
          {touched.province && errors.province && (
            <Form.Control.Feedback type="invalid">
              {errors.province}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group className="mb-3" controlId="formGroupDistrict">
          <Form.Label>{t('district')}</Form.Label>
          <Form.Select
            value={values?.district || ''}
            name="district"
            onBlur={handleBlur}
            onChange={e => {
              const selectedId = e.target.value;
              setFieldValue('district', selectedId);
              updateLocation(selectedId, 'districts', 'communes');
            }}
            className={`${
              touched.district && errors.district ? 'is-invalid' : ''
            }`}
          >
            <option value="">{t('select_district')}</option>
            {locationState.districts.map((data, i) => (
              <option key={`district-${i}`} value={data.id as number}>
                {`${data.name} - ${data.code}`}
              </option>
            ))}
          </Form.Select>
          {touched.district && errors.district && (
            <Form.Control.Feedback type="invalid">
              {errors.district}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group className="mb-3" controlId="formGroupCommune">
          <Form.Label>{t('commune_optional')}</Form.Label>
          <Form.Select
            value={values?.commune || ''}
            name="commune"
            onBlur={handleBlur}
            onChange={e => {
              const selectedId = e.target.value;
              setFieldValue('commune', selectedId);
              updateLocation(selectedId, 'communes', 'villages');
            }}
          >
            <option value="">{t('select_commune')}</option>
            {locationState.communes.map((data, i) => (
              <option key={`commune-${i}`} value={data.id as number}>
                {`${data.name} - ${data.code}`}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group className="mb-3" controlId="formGroupVillage">
          <Form.Label>{t('village_optional')}</Form.Label>
          <Form.Select
            value={values?.village || ''}
            name="village"
            onBlur={handleBlur}
            onChange={e => {
              const selectedId = e.target.value;
              setFieldValue('village', selectedId);
            }}
          >
            <option value="">{t('select_village')}</option>
            {locationState.villages.map((data, i) => (
              <option key={`village-${i}`} value={data.id as number}>
                {`${data.name} - ${data.code}`}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default PreIntakeLocationForm;
