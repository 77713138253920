import { useDispatch, useSelector } from '../../../store';
import { TPreIntakeHistory } from '../../../types/intake';
import PreIntakeHistoryService from '../../../services/intake/PreIntakeHistoryService';
import {
  createOnePreIntakeHistory,
  getAllPreIntakeHistory,
  getOnePreIntakeHistory,
  removeOnePreIntakeHistory,
  updateOnePreIntakeHistory
} from '../../../store/reducers/intake/preIntakeHistorySlice';

const usePreIntakeHistoryHook = () => {
  const dispatch = useDispatch();
  const { preIntakeHistories, preIntakeHistory } = useSelector(
    state => state.preIntakeHistory
  );

  const fetchAllPreIntakeHistory = async (
    preIntakeId: number
  ): Promise<TPreIntakeHistory[]> => {
    try {
      const resp = await PreIntakeHistoryService.fetchAll(preIntakeId);
      const results: TPreIntakeHistory[] =
        resp?.data?.data?.pre_intake_history || [];
      dispatch(getAllPreIntakeHistory({ results }));
      return results;
    } catch (e) {
      dispatch(getAllPreIntakeHistory({ results: [] }));
      console.log('fetchAllPreIntakeHistory', e);
      throw e;
    }
  };
  const fetchOnePreIntakeHistory = async (
    id: number
  ): Promise<TPreIntakeHistory> => {
    try {
      const resp = await PreIntakeHistoryService.fetchOne(id);
      const row = resp?.data?.data || null;

      dispatch(getOnePreIntakeHistory({ row }));
      return row;
    } catch (e) {
      dispatch(getOnePreIntakeHistory({ row: null }));
      console.log('fetchOnePreIntakeHistory', e);
      throw e;
    }
  };
  const createPreIntakeHistory = async (
    data: TPreIntakeHistory
  ): Promise<TPreIntakeHistory> => {
    try {
      const resp = await PreIntakeHistoryService.create(data);
      const row = resp?.data?.data || null;

      dispatch(createOnePreIntakeHistory({ row }));
      return row;
    } catch (e) {
      console.log('createPreIntakeHistory', e);
      throw e;
    }
  };
  const updatePreIntakeHistory = async (
    id: number,
    data: TPreIntakeHistory
  ) => {
    try {
      await PreIntakeHistoryService.update(id, data);
      dispatch(updateOnePreIntakeHistory({ id, row: data }));
    } catch (e) {
      console.log('updatePreIntakeHistory', e);
      throw e;
    }
  };
  const deletePreIntakeHistory = async (id: number) => {
    try {
      await PreIntakeHistoryService.destroy(id);
      dispatch(removeOnePreIntakeHistory({ id }));
    } catch (e) {
      console.log('deletePreIntakeHistory', e);
      throw e;
    }
  };
  return {
    fetchAllPreIntakeHistory,
    fetchOnePreIntakeHistory,
    createPreIntakeHistory,
    updatePreIntakeHistory,
    deletePreIntakeHistory,
    preIntakeHistories,
    preIntakeHistory
  };
};

export default usePreIntakeHistoryHook;
