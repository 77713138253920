import api from '../../utils/api';
import { TModuleFormOption } from '../../types/mdm';
import axios from 'axios';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/mdm/form-option`;

const fetchAll = (slug?: string) => {
  if (slug) {
    return axios.get(`${authEndpoint}?slug=${slug}`);
  } else {
    return axios.get(`${authEndpoint}`);
  }
};

const store = (data: TModuleFormOption) => {
  return api.post(`${authEndpoint}`, data);
};

export default { fetchAll, store };
