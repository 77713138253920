import {
  setAllFormOption,
  storeOneFormOption
} from '../../../store/reducers/mdm/formOptionSlice';
import { TFormOption, TModuleFormOption } from '../../../types/mdm';
import { useDispatch, useSelector } from '../../../store';
import FormOptionService from '../../../services/mdm/FormOptionService';

const useFormOptionHook = () => {
  const dispatch = useDispatch();
  const { formOptions } = useSelector(state => state.formOption);

  const fetchAllFormOption = async (slug?: string): Promise<TFormOption[]> => {
    try {
      const resp = await FormOptionService.fetchAll(slug);
      const results = resp?.data?.data || [];
      if (!slug) {
        dispatch(setAllFormOption({ results }));
      }

      return results;
    } catch (e) {
      console.log('fetchAllFormOption', e);
      throw e;
    }
  };
  const storeFormOption = async (
    data: TModuleFormOption
  ): Promise<TFormOption> => {
    try {
      const resp = await FormOptionService.store(data);
      const row = resp?.data?.data || null;
      dispatch(storeOneFormOption({ row }));
      return row;
    } catch (e) {
      console.log('addOneFormOption', e);
      throw e;
    }
  };

  return {
    fetchAllFormOption,
    storeFormOption,
    formOptions
  };
};

export default useFormOptionHook;
