import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Form, Modal, Row, Stack } from 'react-bootstrap';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { TServiceProvider, TServiceType } from 'types/service-provider';
import useServiceTypeHook from 'hooks/modules/service/useServiceTypeHook';
import ReactSelect from 'components/base/ReactSelect';
import { TReactOption } from 'types';
import { useFormikContext } from 'formik';

interface ServiceProviderFormProps {
  selected: string;
  onClose: () => void;
}

const ServiceProviderForm = ({
  selected,
  onClose
}: ServiceProviderFormProps) => {
  const formik = useFormikContext<TServiceProvider>();
  const { serviceTypes } = useServiceTypeHook();
  const [serviceTypeOptions, setServiceTypeOptions] = React.useState<
    TReactOption[]
  >([]);

  const handleClose = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
  };

  React.useEffect(() => {
    setServiceTypeOptions(
      serviceTypes?.map((data: TServiceType) => {
        return { label: `${data.name} - ${data.code}`, value: `${data.id}` };
      })
    );
  }, [serviceTypes]);

  return (
    <React.Fragment>
      <Modal
        show={selected === 'add' || selected === 'update'}
        onHide={() => {
          handleClose();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {selected === 'add' && 'Add New Service Provider'}
            {selected === 'update' && 'Update Service Provider'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-4 g-3">
            <Col md={6}>
              <Form.Group controlId="service_type">
                <Form.Label>Service Type</Form.Label>
                <Form.Select
                  name="service_type"
                  value={formik?.values?.service_type || ''}
                  onChange={formik.handleChange}
                  className={`${
                    formik?.touched?.service_type &&
                    formik?.errors?.service_type
                      ? 'is-invalid'
                      : ''
                  }`}
                >
                  <option value="">Select Type</option>
                  <option value="Government">Government</option>
                  <option value="Semi-Government">Semi-Government</option>
                  <option value="Private">Private</option>
                </Form.Select>
                {formik.touched.service_type && formik.errors.service_type && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.service_type}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="provider_name">
                <Form.Label>Service Provider Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Service Provider Name"
                  name="name"
                  value={formik?.values?.name || ''}
                  onChange={formik.handleChange}
                  className={`${
                    formik?.touched?.name && formik?.errors?.name
                      ? 'is-invalid'
                      : ''
                  }`}
                />
                {formik.touched.name && formik.errors.name && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Address"
                  name="address"
                  value={formik?.values?.address || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="phone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Phone Number"
                  name="phone_number"
                  value={formik?.values?.phone_number || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  value={formik?.values?.email || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="website">
                <Form.Label>Website</Form.Label>
                <Form.Control
                  type="url"
                  placeholder="Enter URL"
                  name="website"
                  value={formik?.values?.website || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="date">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder=""
                  name="registered_date"
                  value={formik?.values?.registered_date || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="service_type">
                <Form.Label>Services</Form.Label>
                <ReactSelect
                  options={serviceTypeOptions || []}
                  isMulti
                  placeholder="Select Service Type"
                  name="service_type_ids"
                  onBlur={formik?.handleBlur}
                  value={
                    formik?.values?.service_type_ids?.map(id =>
                      serviceTypeOptions?.find(option => option?.value === id)
                    ) || []
                  }
                  onChange={options => {
                    const selectedValues = Array?.isArray(options)
                      ? options?.map(option => option?.value)
                      : [];

                    formik.setFieldValue('service_type_ids', selectedValues);
                  }}
                  className={`${
                    formik?.touched?.service_type_ids &&
                    formik?.errors?.service_type_ids
                      ? 'is-invalid border border-danger rounded'
                      : ''
                  }`}
                />
                {formik.touched.service_type_ids &&
                  formik.errors.service_type_ids && (
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.service_type_ids}
                    </Form.Control.Feedback>
                  )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="email">
                <Form.Label>Area Coverage</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Area Coverage"
                  name="area_coverage"
                  value={formik?.values?.area_coverage || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="email">
                <Form.Label>Status</Form.Label>
                <Stack direction="horizontal" gap={5}>
                  {['Active', 'Inactive']?.map(
                    (data: string, index: number) => (
                      <Form.Check
                        key={index}
                        checked={
                          formik?.values?.status?.toLowerCase() ===
                          data?.toLowerCase()
                        }
                        type="radio"
                        id={data?.toLowerCase()}
                        label={data}
                        name="status"
                        value={data?.toLowerCase() || ''}
                        onChange={formik.handleChange}
                        className={`${
                          formik?.touched?.status && formik?.errors?.status
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                    )
                  )}
                  {formik.touched.status && formik.errors.status && (
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.status}
                    </Form.Control.Feedback>
                  )}
                </Stack>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="notes">
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="note"
                  value={formik?.values?.note || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="phoenix-secondary"
            className="rounded-pill"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="success"
            className="rounded-pill"
            startIcon={<FontAwesomeIcon icon={faAdd} />}
            onClick={() => formik.handleSubmit()}
          >
            {selected === 'update' && 'Update Provider'}
            {selected === 'add' && 'Add New Provider'}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ServiceProviderForm;
