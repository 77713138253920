import { Navbar } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import classNames from 'classnames';
import NavbarBrand from 'components/navbars/nav-items/NavbarBrand';
import NavItemsSlim from 'components/navbars/nav-items/NavItemsSlim';
import NavItems from 'components/navbars/nav-items/NavItems';
import NavbarTopNav from './NavbarTopNav';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import { DashboardSquare01Icon, Database01Icon } from 'hugeicons-react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { checkScope } from '../../../helpers/auth';
import { UilCog } from '@iconscout/react-unicons';

const NavbarTopHorizontal = () => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const {
    config: {
      navbarPosition,
      openNavbarVertical,
      navbarTopShape,
      navbarTopAppearance
    }
  } = useAppContext();

  const { breakpoints } = useBreakpoints();
  const { pathname } = useLocation();

  return (
    <Navbar
      className={classNames('navbar-top fixed-top', {
        'navbar-slim': navbarTopShape === 'slim'
      })}
      expand="lg"
      variant=""
      data-navbar-appearance={navbarTopAppearance === 'darker' ? 'darker' : ''}
    >
      <NavbarBrand />
      <Link
        to="/dashboard"
        className={`btn px-3 d-inline-flex align-items-center ${pathname == '/dashboard' ? 'text-primary' : ''}`}
      >
        <DashboardSquare01Icon className="me-2" size={16} />
        {t('dashboard')}
      </Link>
      {!(navbarPosition === 'combo' && breakpoints.down('lg')) && (
        <Navbar.Collapse
          className="navbar-top-collapse order-1 order-lg-0 pb-0"
          in={openNavbarVertical}
        >
          <NavbarTopNav />
          {checkScope('service-provider-list') && (
            <Link
              to="/service-providers"
              className={`btn px-3 d-inline-flex align-items-center ${pathname == '/service-providers' ? 'text-primary' : ''}`}
            >
              <Database01Icon className="me-2" size={16} />
              {t('service_providers')}
            </Link>
          )}
          {checkScope('setting-list') && (
            <Link
              to="/setting"
              className={`btn px-3 d-inline-flex align-items-center ${pathname == '/setting' ? 'text-primary' : ''}`}
            >
              <UilCog className="me-2" size={16} />
              {t('setting')}
            </Link>
          )}
        </Navbar.Collapse>
      )}
      {navbarTopShape === 'default' ? <NavItems /> : <NavItemsSlim />}
    </Navbar>
  );
};

export default NavbarTopHorizontal;
