import Badge from 'components/base/Badge';
import React from 'react';
import { useTranslation } from 'react-i18next';

type StatusKey =
  | 'active'
  | 'in_active'
  | 'new'
  | 'processing'
  | 'rejected'
  | 'validated';

interface StatusInfo {
  color: 'success' | 'secondary' | 'warning' | 'primary' | 'danger';
  text: string;
}

type StatusBadgeProps = {
  status: string;
};

const StatusBadge: React.FC<StatusBadgeProps> = props => {
  const { status } = props;
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();

  const statusInfo: Record<StatusKey, StatusInfo> = {
    active: {
      color: 'success',
      text: t('active')
    },
    in_active: {
      color: 'secondary',
      text: t('inactive')
    },
    new: {
      color: 'warning',
      text: t('new')
    },
    processing: {
      color: 'primary',
      text: t('processing')
    },
    rejected: {
      color: 'danger',
      text: t('rejected')
    },
    validated: {
      color: 'success',
      text: t('validated')
    }
  };

  const normalizedStatus = status?.toLowerCase().replace(/\s+/g, '-');
  const badgeInfo =
    statusInfo[normalizedStatus as keyof Record<StatusKey, StatusInfo>];

  if (!badgeInfo) {
    return null;
  }

  return (
    <Badge pill variant="phoenix" bg={badgeInfo.color} className="border-0 p-1">
      {badgeInfo.text}
    </Badge>
  );
};

export default StatusBadge;
