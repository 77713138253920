import { RouteObject } from 'react-router-dom';
import MainLayoutProvider from '../providers/MainLayoutProvider';
import AuthGuard from '../utils/route-guard/AuthGuard';
import MainLayout from '../layouts/MainLayout';
import { lazy, Suspense } from 'react';
import PhoenixLoader from '../components/common/PhoenixLoader';
import PreIntakeAdd from '../pages/pre-intake/add';
import PreIntakeValidation from '../pages/pre-intake/validation';

const PreIntake = lazy(() => import('pages/pre-intake'));

const PreIntakeRoutes: RouteObject = {
  path: '/pre-intake',
  element: (
    <MainLayoutProvider>
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    </MainLayoutProvider>
  ),
  children: [
    {
      path: '',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <PreIntake />
        </Suspense>
      )
    },
    {
      path: 'add/:id?',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <PreIntakeAdd />
        </Suspense>
      )
    },
    {
      path: 'validation/:id',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <PreIntakeValidation />
        </Suspense>
      )
    }
  ]
};

export default PreIntakeRoutes;
