import { createSlice } from '@reduxjs/toolkit';
import { TMeta } from 'types';
import { TServiceType } from 'types/service-provider';

interface ServiceTypeState {
  serviceTypes: TServiceType[];
  serviceTypeDetail: TServiceType | null;
  meta: TMeta;
}

const initialState: ServiceTypeState = {
  serviceTypes: [],
  serviceTypeDetail: <TServiceType>{},
  meta: <TMeta>{}
};

const serviceTypeSlice = createSlice({
  name: 'serviceType',
  initialState,
  reducers: {
    getAllServiceTypes(state, action) {
      state.serviceTypes = action.payload.results || [];
    },
    getOneServiceType(state, action) {
      state.serviceTypeDetail = action.payload.row || null;
    },
    createOneServiceType(state, action) {
      state.serviceTypes = [...state.serviceTypes, action.payload.row];
    },
    updateOneServiceType(state, action) {
      state.serviceTypes = state.serviceTypes.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    removeOneServiceType(state, action) {
      state.serviceTypes = state.serviceTypes.filter(
        item => item.id !== action.payload.id
      );
    }
  }
});

export const {
  getAllServiceTypes,
  getOneServiceType,
  createOneServiceType,
  updateOneServiceType,
  removeOneServiceType
} = serviceTypeSlice.actions;
export default serviceTypeSlice.reducer;
