import { createSlice } from '@reduxjs/toolkit';
import { TExecutiveUnitAddress } from '../../types/cases';

interface ExecutiveUnitAddressState {
  executiveUnitAddresses: TExecutiveUnitAddress[];
  executiveUnitAddress: TExecutiveUnitAddress;
}

const initialState: ExecutiveUnitAddressState = {
  executiveUnitAddresses: [],
  executiveUnitAddress: <TExecutiveUnitAddress>{}
};

const executiveUnitAddressSlice = createSlice({
  name: 'executiveUnitAddress',
  initialState,
  reducers: {
    setAllExecutiveUnitAddress(state, action) {
      state.executiveUnitAddresses = action.payload.results || [];
    },
    addOneExecutiveUnitAddress(state, action) {
      state.executiveUnitAddresses = [
        ...state.executiveUnitAddresses,
        action.payload.row
      ];
    },
    setOneExecutiveUnitAddress(state, action) {
      state.executiveUnitAddresses = state.executiveUnitAddresses.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    removeOneExecutiveUnitAddress(state, action) {
      state.executiveUnitAddresses = state.executiveUnitAddresses.filter(
        item => item.id !== action.payload.id
      );
    }
  }
});

export const {
  setAllExecutiveUnitAddress,
  addOneExecutiveUnitAddress,
  setOneExecutiveUnitAddress,
  removeOneExecutiveUnitAddress
} = executiveUnitAddressSlice.actions;
export default executiveUnitAddressSlice.reducer;
