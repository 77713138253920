import { createSlice } from '@reduxjs/toolkit';
import { TPreIntake } from '../../../types/intake';
import { TMeta } from '../../../types';

interface PreIntakeState {
  preIntakes: TPreIntake[];
  preIntake: TPreIntake;
  meta: TMeta;
}

const initialState: PreIntakeState = {
  preIntakes: [],
  preIntake: <TPreIntake>{},
  meta: <TMeta>{}
};

const preIntakeSlice = createSlice({
  name: 'preIntake',
  initialState,
  reducers: {
    getAllPreIntake(state, action) {
      state.preIntakes = action.payload.results || [];
      state.meta = action.payload.meta || {};
    },
    getOnePreIntake(state, action) {
      state.preIntake = action.payload.row || null;
    },
    createOnePreIntake(state, action) {
      state.preIntakes = [...state.preIntakes, action.payload.row];
    },
    updateOnePreIntake(state, action) {
      state.preIntakes = state.preIntakes.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },

    removeOnePreIntake(state, action) {
      state.preIntakes = state.preIntakes.filter(
        item => item.id !== action.payload.id
      );
    }
  }
});

export const {
  getAllPreIntake,
  getOnePreIntake,
  createOnePreIntake,
  updateOnePreIntake,
  removeOnePreIntake
} = preIntakeSlice.actions;
export default preIntakeSlice.reducer;
