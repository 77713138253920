import { Spinner } from 'react-bootstrap';

const PhoenixLoader = () => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100 w-100 ">
      <Spinner animation="grow" />
    </div>
  );
};

export default PhoenixLoader;
