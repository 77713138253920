import SettingsPanel from 'components/settings-panel/SettingsPanel';
import SettingsToggle from 'components/settings-panel/SettingsToggle';
import useToggleStyle from 'hooks/useToggleStyle';
import { useAppContext } from 'providers/AppProvider';
import { useSettingsPanelContext } from 'providers/SettingsPanelProvider';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import useFormOptionHook from './hooks/modules/mdm/useFormOption';
import useLocationHook from './hooks/modules/mdm/useLocationHook';
import useCaseTypeHook from './hooks/modules/mdm/useCaseTypeHook';

const App = () => {
  const { isStylesheetLoaded } = useToggleStyle();
  const { pathname } = useLocation();

  const {
    settingsPanelConfig: { showSettingPanelButton },
    setSettingsPanelConfig
  } = useSettingsPanelContext();

  const {
    config: { theme, isRTL }
  } = useAppContext();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setSettingsPanelConfig({
      openSettingPanel: false
    });
  }, [isRTL]);

  // initial call api
  const { formOptions, fetchAllFormOption } = useFormOptionHook();
  const { caseTypes, fetchAllCaseType } = useCaseTypeHook();
  const {
    fetchAllLocation,
    fetchAllLocationWithTree,
    treeLocations,
    locations
  } = useLocationHook();

  useEffect(() => {
    if (formOptions.length <= 0) {
      fetchAllFormOption().catch(e => console.log(e));
    }
    if (locations.length <= 0) {
      fetchAllLocation().catch(e => console.log(e));
    }
    if (treeLocations.length <= 0) {
      fetchAllLocationWithTree().catch(e => console.log(e));
    }
    if (caseTypes.length <= 0) {
      fetchAllCaseType().catch(e => console.log(e));
    }
  }, []);

  return (
    <>
      {!isStylesheetLoaded ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: theme === 'dark' ? '#000' : '#fff'
          }}
        />
      ) : (
        <>
          <Outlet />
          {showSettingPanelButton && (
            <>
              <SettingsToggle />
              <SettingsPanel />
            </>
          )}
        </>
      )}
    </>
  );
};

export default App;
