import api from '../../utils/api';
import { TCaseType } from '../../types/mdm';
import axios from 'axios';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/mdm/case-type`;

const fetchAll = () => {
  return axios.get(`${authEndpoint}/list`);
};
const fetchAllWithMeta = () => {
  return api.get(`${authEndpoint}`);
};
const fetchOne = (id: number) => {
  return api.get(`${authEndpoint}/${id}`);
};
const create = (data: TCaseType) => {
  return api.post(`${authEndpoint}`, data);
};
const update = (id: number, data: TCaseType) => {
  return api.put(`${authEndpoint}/${id}`, data);
};
const destroy = (id: number) => {
  return api.delete(`${authEndpoint}/${id}`);
};

export default {
  fetchAll,
  fetchAllWithMeta,
  fetchOne,
  create,
  update,
  destroy
};
