import { createSlice } from '@reduxjs/toolkit';
import { TFormOption } from '../../../types/mdm';

interface FormOptionState {
  formOptions: TFormOption[];
}

const initialState: FormOptionState = {
  formOptions: []
};

const formOptionSlice = createSlice({
  name: 'formOption',
  initialState,
  reducers: {
    setAllFormOption(state, action) {
      state.formOptions = action.payload.results || [];
    },
    storeOneFormOption(state, action) {
      state.formOptions = [...state.formOptions, action.payload.row];
    }
  }
});

export const { setAllFormOption, storeOneFormOption } = formOptionSlice.actions;
export default formOptionSlice.reducer;
