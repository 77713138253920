import { TServiceType } from 'types/service-provider';
import { useDispatch, useSelector } from '../../../store';
import {
  getAllServiceTypes,
  getOneServiceType,
  createOneServiceType,
  updateOneServiceType,
  removeOneServiceType
} from '../../../store/reducers/service/serviceTypeSlice';
import ServiceTypeService from 'services/service/ServiceTypeService';

const useServiceTypeHook = () => {
  const dispatch = useDispatch();
  const { serviceTypes, serviceTypeDetail, meta } = useSelector(
    state => state.serviceType
  );

  const fetchAllServiceType = async (): Promise<TServiceType[]> => {
    try {
      const resp = await ServiceTypeService.fetchAll();
      const results: TServiceType[] = resp?.data?.data || [];
      dispatch(getAllServiceTypes({ results }));
      return results;
    } catch (e) {
      dispatch(getAllServiceTypes({ results: [] }));
      console.log('getAllServiceTypes', e);
      throw e;
    }
  };

  const fetchOneServiceType = async (id: number): Promise<TServiceType> => {
    try {
      const resp = await ServiceTypeService.fetchOne(id);
      const row = resp?.data?.data || null;

      dispatch(getOneServiceType({ row }));
      return row;
    } catch (e) {
      console.log('fetchOneServiceType', e);
      throw e;
    }
  };
  const createServiceType = async (
    data: TServiceType
  ): Promise<TServiceType> => {
    try {
      const resp = await ServiceTypeService.create(data);
      const row = resp?.data?.data || null;

      dispatch(createOneServiceType({ row }));
      return row;
    } catch (e) {
      console.log('createServiceType', e);
      throw e;
    }
  };

  const updateServiceType = async (id: number, data: TServiceType) => {
    try {
      await ServiceTypeService.update(id, data);
      dispatch(updateOneServiceType({ id, row: data }));
    } catch (e) {
      console.log('updateServiceType', e);
      throw e;
    }
  };

  const deleteServiceType = async (id: number) => {
    try {
      await ServiceTypeService.destroy(id);
      dispatch(removeOneServiceType({ id }));
    } catch (e) {
      console.log('deleteServiceType', e);
      throw e;
    }
  };

  return {
    fetchAllServiceType,
    fetchOneServiceType,
    createServiceType,
    updateServiceType,
    deleteServiceType,

    meta,
    serviceTypes,
    serviceTypeDetail
  };
};

export default useServiceTypeHook;
