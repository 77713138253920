import { createSlice } from '@reduxjs/toolkit';
import { TPreIntakeValidation } from '../../../types/intake';

interface PreIntakeValidationState {
  preIntakeValidations: TPreIntakeValidation[];
  preIntakeValidation: TPreIntakeValidation;
}

const initialState: PreIntakeValidationState = {
  preIntakeValidations: [],
  preIntakeValidation: <TPreIntakeValidation>{}
};

const preIntakeValidationSlice = createSlice({
  name: 'preIntakeValidation',
  initialState,
  reducers: {
    getAllPreIntakeValidation(state, action) {
      state.preIntakeValidations = action.payload.results || [];
    },
    getOnePreIntakeValidation(state, action) {
      state.preIntakeValidation = action.payload.row || null;
    },
    createOnePreIntakeValidation(state, action) {
      state.preIntakeValidations = [
        ...state.preIntakeValidations,
        action.payload.row
      ];
    },
    updateOnePreIntakeValidation(state, action) {
      state.preIntakeValidations = state.preIntakeValidations.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },

    removeOnePreIntakeValidation(state, action) {
      state.preIntakeValidations = state.preIntakeValidations.filter(
        item => item.id !== action.payload.id
      );
    }
  }
});

export const {
  getAllPreIntakeValidation,
  getOnePreIntakeValidation,
  createOnePreIntakeValidation,
  updateOnePreIntakeValidation,
  removeOnePreIntakeValidation
} = preIntakeValidationSlice.actions;
export default preIntakeValidationSlice.reducer;
