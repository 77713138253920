import { useDispatch, useSelector } from '../../../store';
import { TCaseType } from '../../../types/mdm';
import CaseTypeService from '../../../services/mdm/CaseTypeService';
import {
  createOneCaseType,
  getAllCaseType,
  getOneCaseType,
  removeOneCaseType,
  updateOneCaseType
} from '../../../store/reducers/mdm/caseTypeSlice';

const useCaseTypeHook = () => {
  const dispatch = useDispatch();
  const { caseTypes, caseType } = useSelector(state => state.caseType);

  const fetchAllCaseType = async (): Promise<TCaseType[]> => {
    try {
      const resp = await CaseTypeService.fetchAll();
      const results: TCaseType[] = resp?.data?.data || [];
      dispatch(getAllCaseType({ results }));
      return results;
    } catch (e) {
      dispatch(getAllCaseType({ results: [] }));
      console.log('fetchAllCaseType', e);
      throw e;
    }
  };
  const fetchAllCaseTypeWithMeta = async (): Promise<TCaseType[]> => {
    try {
      const resp = await CaseTypeService.fetchAllWithMeta();
      const results: TCaseType[] = resp?.data?.data || [];
      dispatch(getAllCaseType({ results }));
      return results;
    } catch (e) {
      dispatch(getAllCaseType({ results: [] }));
      console.log('fetchAllCaseType', e);
      throw e;
    }
  };
  const fetchOneCaseType = async (id: number): Promise<TCaseType> => {
    try {
      const resp = await CaseTypeService.fetchOne(id);
      const row = resp?.data?.data || null;

      dispatch(getOneCaseType({ row }));
      return row;
    } catch (e) {
      console.log('fetchOneCaseType', e);
      throw e;
    }
  };
  const createCaseType = async (data: TCaseType): Promise<TCaseType> => {
    try {
      const resp = await CaseTypeService.create(data);
      const row = resp?.data?.data || null;

      dispatch(createOneCaseType({ row }));
      return row;
    } catch (e) {
      console.log('createCaseType', e);
      throw e;
    }
  };
  const updateCaseType = async (id: number, data: TCaseType) => {
    try {
      await CaseTypeService.update(id, data);
      dispatch(updateOneCaseType({ id, row: data }));
    } catch (e) {
      console.log('updateCaseType', e);
      throw e;
    }
  };
  const deleteCaseType = async (id: number) => {
    try {
      await CaseTypeService.destroy(id);
      dispatch(removeOneCaseType({ id }));
    } catch (e) {
      console.log('deleteCaseType', e);
      throw e;
    }
  };
  return {
    fetchAllCaseType,
    fetchAllCaseTypeWithMeta,
    fetchOneCaseType,
    createCaseType,
    updateCaseType,
    deleteCaseType,
    caseTypes,
    caseType
  };
};

export default useCaseTypeHook;
