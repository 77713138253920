import { useDispatch, useSelector } from '../../../store';
import { TPreIntakeValidation } from '../../../types/intake';
import PreIntakeValidationService from '../../../services/intake/PreIntakeValidationService';
import {
  createOnePreIntakeValidation,
  getAllPreIntakeValidation,
  getOnePreIntakeValidation,
  removeOnePreIntakeValidation,
  updateOnePreIntakeValidation
} from '../../../store/reducers/intake/preIntakeValidationSlice';

const usePreIntakeValidationHook = () => {
  const dispatch = useDispatch();
  const { preIntakeValidations, preIntakeValidation } = useSelector(
    state => state.preIntakeValidation
  );

  const fetchAllPreIntakeValidation = async (): Promise<
    TPreIntakeValidation[]
  > => {
    try {
      const resp = await PreIntakeValidationService.fetchAll();
      const results: TPreIntakeValidation[] = resp?.data?.data || [];
      dispatch(getAllPreIntakeValidation({ results }));
      return results;
    } catch (e) {
      dispatch(getAllPreIntakeValidation({ results: [] }));
      console.log('fetchAllPreIntakeValidation', e);
      throw e;
    }
  };
  const fetchOnePreIntakeValidation = async (
    id: number
  ): Promise<TPreIntakeValidation> => {
    try {
      const resp = await PreIntakeValidationService.fetchOne(id);
      const row = resp?.data?.data || null;

      dispatch(getOnePreIntakeValidation({ row }));
      return row;
    } catch (e) {
      dispatch(getOnePreIntakeValidation({ row: null }));
      console.log('fetchOnePreIntakeValidation', e);
      throw e;
    }
  };
  const createPreIntakeValidation = async (
    preIntakeId: number,
    data: TPreIntakeValidation
  ): Promise<TPreIntakeValidation> => {
    try {
      const resp = await PreIntakeValidationService.create(preIntakeId, data);
      const row = resp?.data?.data || null;

      dispatch(createOnePreIntakeValidation({ row }));
      return row;
    } catch (e) {
      console.log('createPreIntakeValidation', e);
      throw e;
    }
  };
  const updatePreIntakeValidation = async (
    id: number,
    data: TPreIntakeValidation
  ) => {
    try {
      await PreIntakeValidationService.update(id, data);
      dispatch(updateOnePreIntakeValidation({ id, row: data }));
    } catch (e) {
      console.log('updatePreIntakeValidation', e);
      throw e;
    }
  };
  const deletePreIntakeValidation = async (id: number) => {
    try {
      await PreIntakeValidationService.destroy(id);
      dispatch(removeOnePreIntakeValidation({ id }));
    } catch (e) {
      console.log('deletePreIntakeValidation', e);
      throw e;
    }
  };
  return {
    fetchAllPreIntakeValidation,
    fetchOnePreIntakeValidation,
    createPreIntakeValidation,
    updatePreIntakeValidation,
    deletePreIntakeValidation,
    preIntakeValidations,
    preIntakeValidation
  };
};

export default usePreIntakeValidationHook;
